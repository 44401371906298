import SockJS from "sockjs-client"
import { Client } from "@stomp/stompjs"
import { createSlice } from "@reduxjs/toolkit";

export const socketSlice = createSlice({
    name: 'ws',
    initialState: {
        clients: [],
        connected: []
    },
    reducers: {
        addSocket: (state, action) => {
            state.clients = {...state.clients, ...{[action.payload.clientName]: action.payload.client}};
        },
        setConnected: (state, action) => {
            state.connected = {...state.connected, ...{[action.payload.clientName]: action.payload.connected}};
        }
    }
})

export const { addSocket, setConnected } = socketSlice.actions;

export const connectSocket = (clientName, url, topics, token = "") => (dispatch) => {
    const stompClient = new Client({
        connectHeaders: {
            token: token
        },
        reconnectDelay: 30000,
        heartbeatIncoming: 10000,
        heartbeatOutgoing: 10000,
    });
    stompClient.webSocketFactory= function () {
        return new SockJS(url, {}, { transports: 'websocket' });
    };
    stompClient.onStompError = (error) => {
        console.log(error);
    }
    stompClient.onWebSocketError = (error) => {
        console.log(error);
    }
    stompClient.onConnect = function (frame) {
        console.log('Connected: ' + frame);
        dispatch(setConnected({clientName: clientName, connected: true}))
        topics.forEach(topic => {
            stompClient.subscribe(topic.path, (message) => {
                topic.callback(message);
            });
        })
    }
    stompClient.onDisconnect = function (frame) {
        console.log('Disconnected: ' + frame);
        dispatch(setConnected({clientName: clientName, connected: false}))
    }
    dispatch(addSocket({clientName: clientName, client: stompClient}));
}

export default socketSlice.reducer;