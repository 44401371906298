import { Box, Text } from "grommet"
import { useParams } from "react-router-dom"
import { useGetRouletteByTokenQuery } from "../api/rouletteWidgetApi";
import { NewWheel } from "../components/wheel/NewWheel";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectSocket } from "../slices/socketReducer";
import useSound from "use-sound";


export const RouletteWidgetPage = () => {

    const params = useParams();
    const [sectors, setSectors] = useState();
    const [currentValue, setCurrentValue] = useState();
    const [wheelProps, setWheelProps] = useState({
        name: 'Theme1',
        isInteractive: false,
        debug: false,
        radius: 0.99,
        itemLabelRadius: 0.85,
        itemLabelRadiusMax: 0.2,
        itemLabelRotation: 0,
        itemLabelAlign: 'right',
        itemLabelColors: ['#fff'],
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Roboto Mono',
        itemLabelFontSizeMax: 25,
        lineWidth: 1,
        lineColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 0,
        pointerAngle: 90,
        overlayImage: `${process.env.PUBLIC_URL}/images/wheel-theme-1.svg`
    });
    const [wheelThemes, setWheelThemes] = useState([
        { image: `wheel-theme-1.svg`, radius: 0.99 },
        { image: `wheel-theme-2.svg`, radius: 0.9 },
        { image: `wheel-theme-3.svg`, radius: 0.99 }
    ]);
    const wheelRef = useRef();
    const dispatch = useDispatch();
    const ws = useSelector(state => state.ws.clients['widgets']);
    const connected = useSelector(state => state.ws.connected['widgets']);
    const {data: rouletteWidgetData, isError: isRouletteWidgetError, isSuccess: isRouletteLoaded} = useGetRouletteByTokenQuery(params.rouletteId);
    const [showWheel, setShowWheel] = useState(false);
    const [play, { sound }] = useSound(`${process.env.PUBLIC_URL}/sounds/soundThemes.mp3`, { 
        volume: 0.2,
        sprite: {
            meme: [0, 59000],
            tick: [60900, 1200]
        }
    });
    const [win, setWin] = useState(false);
    const [donatesList, setDonates] = useState([]);

    const wsCallback = useCallback((messageOutput) => {
        const body = JSON.parse(messageOutput?.body);
        if (isRouletteLoaded && rouletteWidgetData.categoryEntries.length > 0) {
            setShowWheel(true);
            setDonates(prevValueList => [...prevValueList, body]);
        }
    }, [donatesList, rouletteWidgetData])

    const wsTopics = [
        {
            path: `/user/queue/widgets/${params.rouletteId}`,
            callback: wsCallback
        }
    ]

    useEffect(() => {
        if (rouletteWidgetData) {
            if (!ws && !connected) {
                dispatch(connectSocket('widgets', `${process.env.REACT_APP_WEBSOCKET_URL}/widgets`, wsTopics, params.rouletteId))
            }
            if (ws && !connected) {
                console.log("CONNECTING...");
                ws.activate();
            }
        }
        // return () => ws && connected ? ws.deactivate() : null;
    }, [ws, rouletteWidgetData, wsCallback])

    useEffect(() => {
        if (isRouletteLoaded) {
            let imageName = rouletteWidgetData["wheelTheme"]
            let theme = wheelThemes.filter(theme => theme.image === imageName)[0]
            setWheelProps({...wheelProps, ...{
                "overlayImage": `${process.env.PUBLIC_URL}/images/${theme.image}`,
                "radius": theme.radius,
                "borderWidth": +rouletteWidgetData["borderWidth"],
                "borderColor": rouletteWidgetData["borderColor"],
                "itemLabelFont": rouletteWidgetData["font"],
                "itemLabelFontSizeMax": +rouletteWidgetData["fontSize"],
                "itemLabelRadius": +rouletteWidgetData["labelRadius"],
                "itemLabelRotation": +rouletteWidgetData["labelRotation"],
                "lineWidth": +rouletteWidgetData["lineWidth"],
                "lineColor": rouletteWidgetData["lineColor"],
                "itemLabelStrokeWidth": +rouletteWidgetData["labelStrokeWidth"],
                "itemLabelStrokeColor": rouletteWidgetData["labelStrokeColor"]
            }})
            setSectors(rouletteCategoriesToSectors(rouletteWidgetData))
        }
    }, [isRouletteLoaded])

    useEffect(() => {
        if (rouletteWidgetData) {
            const sectors = rouletteCategoriesToSectors(rouletteWidgetData);
            setSectors(sectors)
        }
    }, [rouletteWidgetData])

    const hideWheelCallback = useCallback(() => {
        if (showWheel && donatesList.length === 0) {
            setShowWheel(false)
        }
    }, [showWheel, donatesList])

    useEffect(() => {
        if (showWheel && donatesList.length > 0) {
            setTimeout(() => wheelRef.current.spinWheel(), 2000)
        }
        const timer = setTimeout(hideWheelCallback, 4000);
        return () => clearTimeout(timer);
    }, [showWheel, donatesList, hideWheelCallback])

    useEffect(() => {
        play({ id: 'tick' });
        if (sound) {
            sound.volume(rouletteWidgetData.volume)
        }
    }, [currentValue])

    const rouletteCategoriesToSectors = (rouletteWidget) => {
        if (rouletteWidget.categoryEntries) {
            return rouletteWidget.categoryEntries.flatMap(category => category.hidden ? [] : categoryToSector(rouletteWidget, category));
        }
        return [];
    }

    const categoryRewardsToList = (rewardNames) => {
        return rewardNames.split(",").map(rewardName => rewardName.trim());
    }

    const categoryToSector = (rouletteWidget, category) => {
        const rewardNames = categoryRewardsToList(category.rewardNames)
        return rewardNames.map(rewardName => {
            return {
                id: category.id,
                lotName: rewardName,
                chance: category.rarity / rewardNames.length,
                labelColor: category.labelColor,
                backgroundColor: category.backgroundColor,
                image: category.image ? `${process.env.REACT_APP_API_BASE_URL}/files/${rouletteWidget.ownerId}/${category.image}` : null,
                imageOpacity: category.imageOpacity,
                imageRadius: category.imageRadius,
                imageRotation: category.imageRotation,
                imageScale: category.imageScale,
                
            }
        })
    }

    const handleCurrentIndex = (currentSector) => {
        setCurrentValue(currentSector);
    }

    const handleWinner = (winnerSector) => {
        setWin(true);
    }

    useEffect(() => {
        if (win) {
            if (donatesList.length > 0) {
                setDonates(prevValueList => [...prevValueList.slice(1)])
                setWin(false);
            }
        }
    }, [win])

    return (
        <Box style={{backgroundColor: 'transparent'}} fill>
            {isRouletteLoaded && sectors && (
                <Box style={{opacity: showWheel ? 1 : 0, transition: 'opacity 1s ease-in-out'}} gap="small" fill>
                    <NewWheel
                        sectors={sectors}
                        duration={5}
                        wheelSize={768}
                        wheelProps={wheelProps}
                        persistColors={false}
                        onCurrentIndex={handleCurrentIndex}
                        ref={wheelRef}
                        onWin={handleWinner}
                    />
                    <Box align="center">
                        <Text style={{fontWeight: '700', WebkitTextStroke: '2px black'}} color={'white'} size="4xl">Переможець: {currentValue?.lotName}</Text>
                    </Box>
                </Box>
            )}
        </Box>
    )
}