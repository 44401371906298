import Dexie from "dexie";

const db = new Dexie('whatyouwish');

db.version(1).stores({
    auctionData: 'id,lotName,cost',
    wheelData: 'id,lotName,cost'
})

export const dbAdapter = {
    async saveAuctionData(data, store) {
        await db[store].put(data);
    },
    async saveAuctionDataBulk(data, store) {
        await db[store].bulkPut(data);
    },
    async resetAuctionDataCost(store) {
        await db[store].toCollection().modify(lot => lot.cost = 1);
    },
    async loadAuctionData(store) {
        return await db[store].toCollection()
            .reverse()
            .sortBy('cost');
    },
    async removeAuctionData(data, store) {
        await db[store].delete(data.id);
    },
    async searchAuctionData(term, store) {
        return await db[store].where('lotName')
            .startsWithIgnoreCase(term)
            .reverse()
            .sortBy('cost');
    },
    async clearAuctionData(store) {
        db[store].clear();
    }
}