import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Wheel } from '../../libs/4.3.0_dist_spin-wheel-esm';
import { Box, Image, ResponsiveContext, Stack } from "grommet";
import { easeOutQuad, easeOutQuint } from 'easing-utils';
import { bignumber, randomInt, sum } from "mathjs";

const wheelContainerSize = {
    xsmall: 'medium',
    small: 'medium',
    medium: 'large',
    large: 'large',
    xlarge: 'xxlarge'
}

function getRandom(fromInclusive, toInclusive) {
    return randomInt(fromInclusive, toInclusive + 1)
}

function getRandomRgbColor() {
    return Math.floor(Math.random() * 180) + 50;
}

function generateRandomColor() {
    let [r, g, b] = [getRandomRgbColor(), getRandomRgbColor(), getRandomRgbColor()];
    let hr = r.toString(16).padStart(2, '0');
    let hg = g.toString(16).padStart(2, '0');
    let hb = b.toString(16).padStart(2, '0');
    return `#${hr}${hg}${hb}`;
}

function wrapText(text, maxLength) {
    return text.length > maxLength ? `${text.slice(0, maxLength)}` : text;
}

function calculateWinningElement(chancesArray) {
    const totalChances = sum(chancesArray);

    const randomNumber = Math.random() * totalChances;

    let cumulativeChances = 0;
    for (let i = 0; i < chancesArray.length; i++) {
        cumulativeChances += chancesArray[i];
        if (randomNumber <= cumulativeChances) {
            return i;
        }
    }

    return -1;
}

function getItemLable(item, showChance) {
    if (showChance) {
        return `${wrapText(item.lotName, 15)} (${Number(bignumber(item.chance) * 100).toFixed(2)}%)`;
    }
    return wrapText(item.lotName, 15);
}

const getColor = (persistColors, itemsColors, item) => {
    if (persistColors) {
        return itemsColors[item.id] ? itemsColors[item.id] : generateRandomColor();
    }
    return item.backgroundColor ? item.backgroundColor : generateRandomColor();
}

const getRotateAnimation = (duration) => {
    return duration >= 10 ? easeOutQuad : easeOutQuint;
}

const wheelLabelCoeff = 10;

export const NewWheel = forwardRef(({sectors, duration, onFirstSpin, onWin, onSpin, onCurrentIndex, wheelSize, wheelProps, persistColors = true, emote, showChances = false, ...props}, ref) => {
    const size = useContext(ResponsiveContext);
    const wheel = useRef(null);
    const containerRef = useRef();
    const prevRotate = useRef(0);
    const isSpinning = useRef(false);
    const durationRef = useRef(duration);
    const [wheelSizeVal, setWheelSize] = useState();

    const initWheel = () => {
        let itemsColors = {};
        let items = sectors.map(item => {
            const color = item.backgroundColor ? item.backgroundColor : generateRandomColor();
            itemsColors = {...itemsColors, ...{[item.id]: color}};
            return { 
                label: (item.chance * 1000) >= wheelLabelCoeff ? getItemLable(item, showChances) : '', 
                labelColor: item.labelColor, 
                weight: item.chance, 
                value: item.id, 
                backgroundColor: color, 
                image: item.image,
                imageOpacity: +item.imageOpacity,
                imageRadius: +item.imageRadius,
                imageRotation: +item.imageRotation,
                imageScale: +item.imageScale
            };
        })
        if (items.length == 0) {
            const color = '#01A982';
            items = [{ label: '', weight: 1, value: NaN, backgroundColor: color }];
        }
        if (persistColors) {
            localStorage.setItem('wheel-colors', JSON.stringify(itemsColors));
        }
        wheelProps.items = items;
        wheelProps.onCurrentIndexChange = onWheelCurrentIndex;
        wheelProps.onSpin = onWheelSpin;
        wheelProps.onRest = onWheelRest;
        return new Wheel(containerRef.current, wheelProps);
    }

    const reInitWheelData = () => {
        if (wheel.current) {
            let itemsColors = localStorage.getItem('wheel-colors') ? JSON.parse(localStorage.getItem('wheel-colors')) : [];
            let items = sectors.map(item => {
                const color = getColor(persistColors, itemsColors, item);
                itemsColors = {...itemsColors, ...{[item.id]: color}};
                return { 
                    label: (item.chance * 1000) >= wheelLabelCoeff ? getItemLable(item, showChances) : '',
                    labelColor: item.labelColor, 
                    weight: item.chance, 
                    value: item.id, 
                    backgroundColor: color, 
                    image: item.image,
                    imageOpacity: +item.imageOpacity,
                    imageRadius: +item.imageRadius,
                    imageRotation: +item.imageRotation,
                    imageScale: +item.imageScale
                };
            })
            if (items.length == 0) {
                const color = '#01A982';
                items = [{ label: '', weight: 1, value: NaN, backgroundColor: color }];
            }
            if (persistColors) {
                localStorage.setItem('wheel-colors', JSON.stringify(itemsColors));
            }
            wheelProps.items = items;
            wheelProps.onCurrentIndexChange = onWheelCurrentIndex;
            wheelProps.onSpin = onWheelSpin;
            wheelProps.onRest = onWheelRest;
            wheelProps.rotation = prevRotate.current;
            wheel.current.init(wheelProps);
        }
    }

    const onWheelCurrentIndex = ({currentIndex}) => {
        if (onCurrentIndex) {
            onCurrentIndex(sectors[currentIndex])
        }
    }

    const onWheelSpin = () => {
        isSpinning.current = true;
        if (onSpin) {
            onSpin(isSpinning.current, durationRef.current)
        }
    }

    const onWheelRest = useCallback(({currentIndex}) => {
        isSpinning.current = false;
        if (onSpin) {
            onSpin(isSpinning.current, durationRef.current)
        }
        const winItem = wheel.current.items[currentIndex];
        if (onWin) {
            onWin(sectors.find(sector => sector.id === winItem.value))
        }
    }, [onWin])

    const spinWheel = () => {
        if(!isSpinning.current && sectors.length > 0) {
            // const randomRotate = getRandom(360, 360 * 2) * duration;
            // wheel.current.spinTo(prevRotate.current + randomRotate, duration * 1000, easeOutQuad);
            const winningIndex = calculateWinningElement(sectors.map(sector => sector.chance));
            const numberOfRotations = getRandom(duration, duration * 2)
            wheel.current.spinToItem(winningIndex, duration * 1000, false, numberOfRotations, 1, getRotateAnimation(duration))
            // prevRotate.current = prevRotate.current + randomRotate;
        }
    }

    useImperativeHandle(ref, () => ({
        spinWheel
    }), [spinWheel]);

    useEffect(() => {
        if (wheel.current == null) {
            wheel.current = initWheel();
        }
    }, [])

    useEffect(() => {
        reInitWheelData();
    }, [sectors, wheelSizeVal, wheelProps, showChances])

    useEffect(() => {
        setWheelSize(wheelSize);
    }, [wheelSize])

    useEffect(() => {
        durationRef.current = duration;
    }, [duration])

    return (
        <Stack anchor="center">
            <Box {...props} height={wheelSizeVal ? `${wheelSizeVal}px` : wheelContainerSize[size]} ref={containerRef}></Box>
            {emote && (
                <Box><Image style={{borderRadius: '50%'}} height={`${wheelSizeVal * 0.13}px`} width={`${wheelSizeVal * 0.13}px`} fit="contain" src={emote} /></Box>
            )}
        </Stack>
    )
})