import { Box, Button, Card, CardBody, Spinner, Stack, TextInput } from "grommet"
import { Checkmark, Copy, Update } from "grommet-icons"
import { useEffect, useState } from "react"
import { useGenerateDonatelloApiKeyMutation, useGetDonatelloApiKeyQuery, useGetDonatesUrlQuery } from "../../api/donatesApi"
import { DonatelloSettingsLayout } from "./DonatelloSettingsLayout"
import { TwitchAuth } from "../auth/TwitchAuth"
import { useSelector } from "react-redux"
import { authenticationSelector } from "../../slices/authSlice"
import { useTranslation } from "react-i18next"

export const DonatelloSettings = () => {

    const { t, ready } = useTranslation('donatello-settings', { useSuspense: false });
    const isAuthenticated = useSelector(authenticationSelector);
    const {data: apiKeyResponse, isLoading: isApiKeyLoading, isError: isApiKeyError} = useGetDonatelloApiKeyQuery(undefined, {skip: !isAuthenticated});
    const {data: donationCallbackResponse, isLoading: isCallbackUrlLoading, isError: isCallbackError} = useGetDonatesUrlQuery(undefined, {skip: !isAuthenticated});
    const [generateDonatelloApiKey, {isLoading: isGeneratingApiKey}] =  useGenerateDonatelloApiKeyMutation();

    const [apiCopied, setApiCopied] = useState(false);
    const [callbackCopied, setCallbackCopied] = useState(false);
    const [apiKey, setApiKey] = useState();

    const handleApiKeyCopy = () => {
        navigator.clipboard.writeText(apiKey)
        setApiCopied(true);
        setTimeout(() => {
            setApiCopied(false);
        }, 1000)
    }

    const handleDonationCallbackCopy = () => {
        navigator.clipboard.writeText(donationCallbackResponse)
        setCallbackCopied(true);
        setTimeout(() => {
            setCallbackCopied(false);
        }, 1000)
    }

    const handleApiKeyUpdate = () => {
        generateDonatelloApiKey()
        .then((response) => setApiKey(response.data));
    }

    useEffect(() => {
        setApiKey(apiKeyResponse)
    }, [apiKeyResponse])

    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        return (
            <Card background="#222222" fill="vertical">
                <CardBody pad="medium">
                    {isAuthenticated && (
                        <DonatelloSettingsLayout 
                            apiKeyInput={
                                isApiKeyLoading && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="text" value={t('fetching')}/>
                                        <Button icon={<Copy/>}/>
                                        <Box flex="false">
                                            <Button icon={<Update/>}/>
                                        </Box>
                                    </Box>
                                ) ||
                                isApiKeyError && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="text" value={t('fetchingError')}/>
                                        <Button icon={<Copy/>}/>
                                        <Box flex="false">
                                            <Button icon={<Update/>}/>
                                        </Box>
                                    </Box>
                                ) ||
                                apiKey && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="password" value={apiKey}/>
                                        <Button icon={apiCopied ? <Checkmark/> : <Copy/>} onClick={handleApiKeyCopy}/>
                                        <Box flex="false" animation={isGeneratingApiKey ? 'rotateRight' : ''}>
                                            <Button icon={<Update/>} onClick={handleApiKeyUpdate}/>
                                        </Box>
                                    </Box>
                                )
                            }
                            donateUrlInput={
                                donationCallbackResponse && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={donationCallbackResponse}/>
                                        <Button icon={callbackCopied ? <Checkmark/> : <Copy/>} onClick={handleDonationCallbackCopy}/>
                                    </Box>
                                ) ||
                                isCallbackUrlLoading && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={t('fetching')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                ) ||
                                isCallbackError && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={t('fetchingError')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                )
                            } 
                        />
                    )}
                    {!isAuthenticated && (
                        <Stack>
                            <DonatelloSettingsLayout 
                                apiKeyInput={
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="text" value={t('signInPlaceholder')}/>
                                        <Button icon={<Copy/>}/>
                                        <Box flex="false">
                                            <Button icon={<Update/>}/>
                                        </Box>
                                    </Box>
                                } 
                                donateUrlInput={
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={t('signInPlaceholder')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                } 
                            />
                            <Box background={{color: '#222222', opacity: 'strong'}} fill justify="center" align="center">
                                <TwitchAuth/>
                            </Box>
                        </Stack>
                    )}
                </CardBody>
            </Card>
        )
    }
}