import { DonatelloSettings } from "./DonatelloSettings"
import { DonatikSettings } from "./DonatikSettings"
import { TwitchSettings } from "./TwitchSettings"

export const Settings = () => {

    return (
        <>
            <DonatelloSettings/>
            <DonatikSettings/>
            <TwitchSettings/>
        </>
    )
}