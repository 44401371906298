import { useContext, useState } from 'react';
import { Box, ResponsiveContext, Card, CardBody } from 'grommet';
import { ResponsiveGrid } from '../ResponsiveGrid';

// if size if xsmall, we only 1 column
// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 2 columns with 2/3 1/3 proportions
const columns = {
    xsmall: ["auto"],
    small: ["auto"],
    medium: ["auto"],
    large: ["3/4", "1/4"],
    xlarge: ["3/4", "1/4"]
  };
  
  // if size if xsmall, we have 2 rows
  // if size if small, we have 2 rows
  // if size if medium, we have 2 rows
  // if size if large or xlarge, we have 1 row
  const rows = {
    xsmall: ["flex", "flex"],
    small: ["flex", "flex"],
    medium: ["flex", "flex"],
    large: ["flex"],
    xlarge: ["flex"]
  };

  // set the different areas you need for every size
const areas = {
    xsmall: [
        ['mainArea'],
        ['settingsArea']
    ],
    small: [
        ['mainArea'],
        ['settingsArea']
    ],
    medium: [
        ['mainArea'],
        ['settingsArea']
    ],
    large: [
        ['mainArea', 'settingsArea']
    ],
    xlarge: [
        ['mainArea', 'settingsArea']
    ]
};

const paddingsRight = {
    xsmall: {},
    small: {},
    medium: {},
    large: {right: "small"},
    xlarge: {right: "small"}
}

const paddingsLeft = {
    xsmall: {},
    small: {},
    medium: {},
    large: {left: "small"},
    xlarge: {left: "small"}
}

const gap = {
    xsmall: 'medium',
    small: 'medium',
    medium: 'medium',
    large: 'none',
    xlarge: 'none'
}

export const LotsTableGrid = ({children, smallDataLength}) => {
    const size = useContext(ResponsiveContext);
    const [inputDonatesHeight, setInputDonatesHeight] = useState();

    // const measuredRef = useCallback(node => {
    //     if (!node) return;
    //     const resizeObserver = new ResizeObserver(() => {
    //         setInputDonatesHeight(node.getBoundingClientRect().height);
    //     });
    //     resizeObserver.observe(node);
    // }, []);

    return (
        <ResponsiveGrid 
            overrideRows={rows}
            overrideColumns={columns}
            areas={areas}
            gap={gap[size]}
            margin="none"
        >
            <Box gridArea='mainArea' pad={paddingsRight[size]}>
                <Card background="#222222" fill={false} /*ref={measuredRef}*/ height={smallDataLength ? 'large' : ''}>
                  <CardBody pad="medium">{children[0]}</CardBody>
                </Card>
            </Box>
            <Box gridArea='settingsArea' pad={paddingsLeft[size]}>
                <Card background="#222222" /*style={{height: inputDonatesHeight}}*/>
                    <CardBody pad="medium">{children[1]}</CardBody>
                </Card>
                <Box>
                    {children[2]}
                </Box>
            </Box>
        </ResponsiveGrid>
    );
};