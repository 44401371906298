import { Menu } from "grommet";
import { Language } from "grommet-icons";
import { useTranslation } from "react-i18next";

export const LanguageSelector = () => {
    const {i18n} = useTranslation();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
    }

    const langs = [
        { 
            label: '🇺🇦 Українська',
            onClick: () => setLanguage('uk')
        },
        { 
            label: '🇬🇧 English',
            onClick: () => setLanguage('en')
        }
    ];
    return (
        <Menu icon={<Language/>} items={langs}/>
    )
}