import { Box, Heading, ResponsiveContext, Spinner } from "grommet"
import { TwitchReward } from "./TwitchReward"
import { ResponsiveGrid } from "../ResponsiveGrid"
import { useContext } from "react";
import { useTranslation } from "react-i18next";

// if size if xsmall, we only 1 column
// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 2 columns with 2/3 1/3 proportions
const columns = {
    xsmall: ["auto"],
    small: ["auto"],
    medium: ["auto", "auto"],
    large: ["auto", "auto", "auto", "auto", "auto", "auto"],
    xlarge: ["auto", "auto", "auto", "auto", "auto", "auto"]
};

// if size if xsmall, we have 2 rows
// if size if small, we have 2 rows
// if size if medium, we have 2 rows
// if size if large or xlarge, we have 1 row
const rows = {
    xsmall: ["flex", "flex"],
    small: ["flex", "flex"],
    medium: ["flex"],
    large: ["flex"],
    xlarge: ["flex"]
};

const gap = {
    xsmall: 'medium',
    small: 'medium',
    medium: 'medium',
    large: 'medium',
    xlarge: 'medium'
}

export const TwitchRewards = ({data, onRewardDelete}) => {
    const size = useContext(ResponsiveContext);
    const { t } = useTranslation('twitch-settings')

    return (
        <>
            <Heading level={3} margin={{bottom: 'small'}}>{t('rewardsListTitle')}</Heading>
            <Box>
                <ResponsiveGrid
                    overrideRows={rows}
                    overrideColumns={columns}
                    // areas={areas}
                    gap={gap[size]}
                    margin="none"
                >
                    {data.map(row => (
                        <TwitchReward key={row.id} id={row.id} name={row.rewardName} cost={row.cost} onRewardDelete={onRewardDelete}/>
                    ))}
                </ResponsiveGrid>
            </Box>
        </>
    )
}