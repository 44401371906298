import { Box, Button, Card, CardBody, Spinner, Stack, TextInput } from "grommet"
import { Checkmark, Copy, Update, Upload } from "grommet-icons"
import { useEffect, useState } from "react"
import { useGetDonatikApiKeyQuery, useGetDonatikDonatesUrlQuery, useSetDonatikApiKeyMutation } from "../../api/donatesApi"
import { TwitchAuth } from "../auth/TwitchAuth"
import { useSelector } from "react-redux"
import { authenticationSelector } from "../../slices/authSlice"
import { useTranslation } from "react-i18next"
import { DonatikSettingsLayout } from "./DonatikSettingsLayout"

export const DonatikSettings = () => {

    const { t, ready } = useTranslation('donatik-settings', { useSuspense: false });
    const isAuthenticated = useSelector(authenticationSelector);
    const { data: donatikApiKey, isLoading: isLoadingDonatikApiKey, isError: isDonatikApiKeyError } = useGetDonatikApiKeyQuery(undefined, {skip: !isAuthenticated});
    const { data: donatikDonationCallbackResponse, isSuccess: isCallbackUrlSuccess, isLoading: isCallbackUrlLoading, isError: isCallbackError, error: donationCallbackError } = useGetDonatikDonatesUrlQuery(undefined, {skip: !isAuthenticated});
    const [setDonatikApiKey, { isLoading: isDonatikKeyLoading, isSuccess: isApiKeySavedSuccesfully }] = useSetDonatikApiKeyMutation();

    const [apiKey, setApiKey] = useState();
    const [callbackCopied, setCallbackCopied] = useState(false);
    const [callbackUrl, setCallbackUrl] = useState();

    const handleDonationCallbackCopy = () => {
        navigator.clipboard.writeText(callbackUrl)
        setCallbackCopied(true);
        setTimeout(() => {
            setCallbackCopied(false);
        }, 1000)
    }

    const handleApiKeyUpdate = () => {
        if (apiKey) {
            setDonatikApiKey({ apiKey });
        }
    }

    useEffect(() => {
        setApiKey(donatikApiKey)
    }, [donatikApiKey])

    useEffect(() => {
        if (isCallbackUrlSuccess) {
            setCallbackUrl(donatikDonationCallbackResponse);
        }
    }, [isCallbackUrlSuccess, donatikDonationCallbackResponse])
    
    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        return (
            <Card background="#222222" fill="vertical" margin={{top: 'medium'}}>
                <CardBody pad="medium">
                    {isAuthenticated && (
                        <DonatikSettingsLayout 
                            apiKeyInput={
                                isLoadingDonatikApiKey && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="text" value={t('fetching')}/>
                                        <Box flex="false">
                                            <Button icon={<Upload/>}/>
                                        </Box>
                                    </Box>
                                ) ||
                                isDonatikApiKeyError && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="text" value={t('fetchingError')}/>
                                        <Button icon={<Copy/>}/>
                                        <Box flex="false">
                                            <Button icon={<Upload/>}/>
                                        </Box>
                                    </Box>
                                ) ||
                                apiKey !== undefined && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="password" value={apiKey} placeholder={t('apiInputPlaceholder')} onChange={(event) => setApiKey(event.target.value)}/>
                                        <Box flex="false">
                                            <Button icon={<Upload/>} onClick={handleApiKeyUpdate} busy={isDonatikKeyLoading}/>
                                        </Box>
                                    </Box>
                                )
                            }
                            donateUrlInput={
                                callbackUrl && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={callbackUrl}/>
                                        <Button icon={callbackCopied ? <Checkmark/> : <Copy/>} onClick={handleDonationCallbackCopy}/>
                                    </Box>
                                ) ||
                                isCallbackError && donationCallbackError === 400 && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={''} placeholder={t('callbackUrlPlaceholder')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                ) ||
                                isCallbackError && donationCallbackError !== 400 && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={t('fetchingError')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                ) ||
                                isCallbackUrlLoading && (
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={t('fetching')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                )
                            } 
                        />
                    )}
                    {!isAuthenticated && (
                        <Stack>
                            <DonatikSettingsLayout 
                                apiKeyInput={
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain type="text" value={t('signInPlaceholder')}/>
                                        <Button icon={<Copy/>}/>
                                        <Box flex="false">
                                            <Button icon={<Update/>}/>
                                        </Box>
                                    </Box>
                                } 
                                donateUrlInput={
                                    <Box direction="row" border round="xsmall" width={'medium'}>
                                        <TextInput plain value={t('signInPlaceholder')}/>
                                        <Button icon={<Copy/>}/>
                                    </Box>
                                } 
                            />
                            <Box background={{color: '#222222', opacity: 'strong'}} fill justify="center" align="center">
                                <TwitchAuth/>
                            </Box>
                        </Stack>
                    )}
                </CardBody>
            </Card>
        )
    }
}