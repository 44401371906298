import { setItems } from "../slices/auctionDataSlice";
import { actions } from "./actions";
import { calculateChances } from "./utils/storeUtils";

export const calculateChancesMiddleware =
	({ getState }) =>
	(next) =>
	async (action) => {
		const result = next(action);
		if (actions.includes(action.type)) {
			const items = [...getState().auctionData.items];
			const calculatedItems = calculateChances(
				items,
				getState().auctionData.totalCost
			);
			return next(setItems(calculatedItems));
		}
		return result;
	};
