import { recalculateDonatesSuggestions } from "../slices/donatesSlice";

export const calculateDonateSuggestionsMiddleware = ({ getState }) => (next) => async (action) => {
	const result = next(action);
	if (["donates/addNewDonate", "donates/setDonates"].includes(action.type)) {
		const auctionDataLotNames = [...getState().auctionData.items].map((row) => row.lotName);
		return next(recalculateDonatesSuggestions(auctionDataLotNames));
	}
	return result;
};
