import { divide, subtract } from 'mathjs';
import { wheelModes } from '../../slices/wheelDataSlice';

export const calculateChances = (items, totalCost) => {
	return items.map((row) => ({
		...row,
		...{ chance: row.cost / totalCost },
	}));
};

export const calculateChancesForDropout = (items, totalCost) => {
	return items.map((row) => {
		const chance = divide(
			subtract(1, divide(row.cost, totalCost)),
			items.length > 1 ? subtract(items.length, 1) : 1
		);
		if (chance > 0) {
			return { ...row, ...{ chance: chance } };
		}
		return { ...row, ...{ chance: 1 } };
	});
};

export const calculateItems = (getState) => {
	const wheelMode = getState().wheelData.wheelMode;
	const supergameEnabled = getState().wheelSettings.supergameEnabled;
	const supergameCostThreshold = Number(
		getState().wheelSettings.supergameCostThreshold
	);
	const supergameStarted = getState().wheelSettings.supergameStarted;
	if (wheelMode === wheelModes.SIMPLE_MODE) {
		const items = [...getState().auctionData.items];
		return calculateChances(items, getState().auctionData.totalCost);
	} else if (wheelMode === wheelModes.DROPOUT_MODE) {
		if (supergameEnabled) {
			if (supergameStarted) {
                const wheelData = [...getState().wheelData.wheelData];
                const firstRoundWinner = { ...wheelData[0], ...{ cost: supergameCostThreshold }};
				const items = [...[...getState().auctionData.items].filter(
					(row) => row.cost >= supergameCostThreshold
				), firstRoundWinner];
				return calculateChancesForDropout(
					items,
					items.reduce(
						(accumulator, currentItem) =>
							accumulator + currentItem.cost,
						0
					)
				);
			}
			const items = [...getState().auctionData.items].filter(
				(row) => row.cost < supergameCostThreshold
			);
			return calculateChancesForDropout(
				items,
				items.reduce(
					(accumulator, currentItem) =>
						accumulator + currentItem.cost,
					0
				)
			);
		}
		const items = [...getState().auctionData.items];
		return calculateChancesForDropout(
			items,
			getState().auctionData.totalCost
		);
	}
};

export const calculateWheelItems = (getState) => {
	const wheelMode = getState().wheelData.wheelMode;
	if (wheelMode === wheelModes.SIMPLE_MODE) {
		const items = [...getState().wheelData.wheelData];
		return calculateChances(items, getState().wheelData.totalCost);
	} else if (wheelMode === wheelModes.DROPOUT_MODE) {
		const items = [...getState().wheelData.wheelData];
		return calculateChancesForDropout(
			items,
			getState().wheelData.totalCost
		);
	}
};
