import { createSlice } from "@reduxjs/toolkit";
import { recalculateSuggestedLots } from "../utils/suggestedLotsUtils";

export const donatesSlice = createSlice({
    name: 'donates',
    initialState: {
        items: [],
        autoAdd: true
    },
    reducers: {
        addNewDonate: (state, action) => {
            const donate = action.payload;
            state.items.push(donate);
        },
        setDonates: (state, action) => {
            const donates = action.payload;
            state.items = donates;
        },
        removeDonateById: (state, action) => {
            const donateId = action.payload;
            const newItems = state.items.filter(row => row.id !== donateId);
            state.items = newItems;
        },
        recalculateDonatesSuggestions: (state, action) => {
            const lotsNames = action.payload;
            state.items = recalculateSuggestedLots(state.items, lotsNames);
        }
    }
})

export const { addNewDonate, setDonates, removeDonateById, recalculateDonatesSuggestions } = donatesSlice.actions;

export default donatesSlice.reducer;