import { useEffect, useState } from "react";
import { useGetCurrentUserQuery, usePartialUserUpdateMutation } from "../../api/userApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authenticationSelector } from "../../slices/authSlice";
import { Box, CheckBox } from "grommet";

export const DonatesControl = () => {

    const { t } = useTranslation('donate-card');
    const isAuthenticated = useSelector(authenticationSelector);
    const { data: currentUser, isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(undefined, {skip: !isAuthenticated});
    const [partialUserUpdate] = usePartialUserUpdateMutation();
    const [donatesEnabled, setDonatesEnabled] = useState();

    useEffect(() => {
        if (isCurrentUserSuccess) {
            setDonatesEnabled(currentUser.donatesEnabled);
        }
    }, [isCurrentUserSuccess])

    const handleDonatesEnabled = (value) => {
        partialUserUpdate({id: currentUser.id, donatesEnabled: value})
        .then(() => setDonatesEnabled(value));
    }

    return (
        <>
            {isAuthenticated && (
                <Box margin={{ top: 'medium' }}>
                    <CheckBox pad={'none'} toggle checked={donatesEnabled} label={t('enableDonates')} onClick={(event) => handleDonatesEnabled(event.target.checked)}/>
                </Box>
            )}
        </>
    )
}