import { setWheelMode, wheelModes } from "../slices/wheelDataSlice";

export const setWheelSpinModeMiddleware =
	({ getState }) =>
	(next) =>
	async (action) => {
		const result = next(action);
		if (["wheelSettings/setWheelSpinMode"].includes(action.type)) {
			const wheelSpinMode = action.payload;
			if (wheelSpinMode === 1) {
				return next(setWheelMode(wheelModes.SIMPLE_MODE));
			} else if (wheelSpinMode == 2) {
				return next(setWheelMode(wheelModes.DROPOUT_MODE));
			}
		}
		return result;
	};
