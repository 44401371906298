import { setWheelData } from "../slices/wheelDataSlice";
import { actions } from "./actions";
import { calculateItems } from "./utils/storeUtils";

export const copyWheelDataMiddleware =
	({ getState }) =>
	(next) =>
	async (action) => {
		const result = next(action);
		if ([...actions, "auctionData/clearLots"].includes(action.type)) {
			const calculatedItems = calculateItems(getState);
			return next(setWheelData(calculatedItems));
		}
		return result;
	};
