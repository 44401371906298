import { DndContext, DragOverlay, MouseSensor, pointerWithin, useSensor, useSensors } from "@dnd-kit/core"
import { LotsTableGrid } from "./LotsTableGrid"
import { AuctionTable } from "./AuctionTable"
import { DonatesTimer } from "../input-donates/DonatesTimer"
import { DonatesList } from "../input-donates/DonatesList"
import { DonateCard } from "../input-donates/DonateCard"
import { useEffect, useState } from "react"
import { useLazyGetUserDonatesQuery } from "../../api/userApi"
import { useDispatch, useSelector } from "react-redux"
import { removeDonateById, setDonates } from "../../slices/donatesSlice"
import { authenticationSelector } from "../../slices/authSlice"
import { updateLotCostByIndex } from "../../slices/auctionDataSlice"
import {CSS} from '@dnd-kit/utilities';
import { DonatesControl } from "../input-donates/DonatesControl"

const dropAnimation = { 
    keyframes({ transform }) { 
        return [
            {transform: CSS.Transform.toString(transform.initial)},
            {
                transform: CSS.Transform.toString({
                    ...transform.final,
                    scaleX: 0.95,
                    scaleY: 0.95,
                }),
            },
        ];
    },
    sideEffects({ active }) {
        return () => {
            active.node.style.opacity = '';
        };
    },
    duration: 500
}

export const AuctionViewTab = () => {

    const mouseSensor = useSensor(MouseSensor, {
		// Require the mouse to move by 10 pixels before activating
		activationConstraint: {
			distance: 10,
			// delay: 100,
			// tolerance: 0.1
		},
	});

	const sensors = useSensors(mouseSensor);

    const dispatch = useDispatch();
    const ws = useSelector((state) => state.ws.clients["donates"]);
	const connected = useSelector((state) => state.ws.connected["donates"]);
    const isAuthenticated = useSelector(authenticationSelector);
    const [getUserDonates] = useLazyGetUserDonatesQuery();
	const { items, totalCost, searchTerm } = useSelector(
		(state) => state.auctionData
	);
	const donates = useSelector((state) => state.donates.items);
	const [isDragging, setIsDragging] = useState(false);
	const [previewItem, setPreviewItem] = useState();

	useEffect(() => {
		if (items) {
			// just trigger middlware recalculation
			dispatch(setDonates(donates));
		}
	}, [items]);

	useEffect(() => {
		if (isAuthenticated) {
			getUserDonates().then((response) =>
				dispatch(setDonates(response.data))
			);
		}
	}, [isAuthenticated]);

    function handleDragStart(e) {
		setIsDragging(true);
		const donate = donates[e.active.data.current.index];
		setPreviewItem(donate);
	}
	
	function handleDragEnd(e) {
		setIsDragging(false);
		setPreviewItem(null);
		const donate = donates[e.active.data.current.index];
		const lotIndex = e.over?.data?.current?.index;   
        const id = e.over?.data?.current?.id;    
		if (lotIndex != undefined) {
			dispatch(updateLotCostByIndex({ id: id, index: lotIndex, cost: donate.amount}));
			dispatch(removeDonateById(donate.id));
			if (ws !== undefined && connected !== undefined && connected) {
				ws.publish({ destination: '/app/donates/remove', body: JSON.stringify(donate) });
			}
		}
	}

    return (
        <DndContext 
            sensors={sensors} 
            onDragStart={handleDragStart} 
            onDragEnd={handleDragEnd}
            collisionDetection={pointerWithin}
            autoScroll={{ layoutShiftCompensation: false }}
        >
            <LotsTableGrid smallDataLength={items.length <= 10}>
                <AuctionTable
                    items={items}
                    totalCost={totalCost}
                    searchTerm={searchTerm}
                />
                <>
                    <DonatesTimer />
                    <DonatesControl />
                </>
                <DonatesList data={donates} />
            </LotsTableGrid>
            <DragOverlay dropAnimation={dropAnimation}>
                {isDragging ? <DonateCard item={previewItem} /> : null}
            </DragOverlay>
        </DndContext>
    )
}