import { Box, Button, Card, CardBody, CardFooter, CheckBox, FileInput, Layer } from "grommet"
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { uploadAuctionData } from "../../../slices/auctionDataSlice";
import { useState } from "react";

export const UploadDialog = ({ show, onClose }) => {

    const { t } = useTranslation(['lots-table', 'wheel']);
    const dispatch = useDispatch();

    const [fileUploadMode, setFileUploadMode] = useState(false);
    const [fileUpload, setFileUpload] = useState();

    const handleFileUpload = () => {
      dispatch(uploadAuctionData({fileData: fileUpload, merge: fileUploadMode}));
      onClose();
    }

    if (show) {
        return (
            <Layer 
                onEsc={onClose}
                onClickOutside={onClose}
              >
                  <Card height="medium" width="large" background="#222222">
                    <CardBody pad="medium" justify="center">
                        <Box direction="column" gap="medium">
                          <FileInput onChange={(event, { files }) => setFileUpload(files[0])} />
                          <CheckBox checked={fileUploadMode} onChange={(event) => setFileUploadMode(event.target.checked)} toggle label={t('fileUploadMode')} />
                        </Box>
                    </CardBody>
                    <CardFooter pad="medium">
                        <Button label={t('wheel:resetDialogCancel')} onClick={onClose}/>
                        <Button label={t('fileUploadButton')} primary onClick={() => handleFileUpload()}/>
                    </CardFooter>
                  </Card>
              </Layer>
        )
    }
    return null;
}