import { setItems } from "../slices/auctionDataSlice";
import { actions } from "./actions";

export const sortItemsMiddleware =
	({ getState }) =>
	(next) =>
	async (action) => {
		const result = next(action);
		if (actions.includes(action.type)) {
			const items = [...getState().auctionData.items];
			const sortedItems = sortItems(items);
			return next(setItems(sortedItems));
		}
		return result;
	};

const sortItems = (items) => {
	return items.sort((leftItem, rightItem) => rightItem.cost - leftItem.cost);
};
