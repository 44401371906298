import { createSlice } from "@reduxjs/toolkit";

const getAuthentication = () => {
    const auth = localStorage.getItem('auth');
    return typeof auth !== "undefined" ? JSON.parse(auth) : false;
}

const getAuthenticationProgress = () => {
    const authProgress = sessionStorage.getItem('auth-progress');
    return typeof authProgress !== "undefined" ? JSON.parse(authProgress) : false;
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: getAuthentication(),
        isAuthenticating: getAuthenticationProgress()
    },
    reducers: {
        setAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
            localStorage.setItem('auth', action.payload);
        },
        setIsAuthenticating: (state, action) => {
            state.isAuthenticating = action.payload;
            sessionStorage.setItem('auth-progress', action.payload);
        }
    }
})

export const authenticationSelector = state => state.auth.isAuthenticated;
export const authInProgressSelector = state => state.auth.isAuthenticating;

export const { setAuthenticated, setIsAuthenticating } = authSlice.actions;

export default authSlice.reducer;