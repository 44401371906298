import { Box, Button, Card, CardBody, CardHeader, Spinner, Text } from "grommet"
import { FormView, Hide, SettingsOption } from "grommet-icons"
import { useEffect, useState } from "react"
import { usePartialUpdateRouletteCategoryMutation } from "../../api/rouletteWidgetApi"
import { useTranslation } from "react-i18next"

const rarityOptions = {
    500: {
        name: 'Common',
        color: '#fdfefe'
    },
    250: {
        name: 'Uncommon',
        color: '#2fde78'
    },
    160: {
        name: 'Rare',
        color: '#385ef5'
    },
    70: {
        name: 'Epic',
        color: '#a14ac7'
    },
    15: {
        name: 'Legendary',
        color: '#f1c40f'
    },
    7: {
        name: 'Methyc',
        color: '#D35400'
    },
    1: {
        name: 'Relic',
        color: '#c70404'
    }
}

export const RouletteItem = ({item, dropChance, onEdit, displayEditForm}) => {
    const { t, ready } = useTranslation('roulette', { useSuspense: false });
    const [isEditFormDisplayed, setEditFormDisplayed] = useState(displayEditForm);
    const [isHideButtonDisplayed, setHideButtonDisplayed] = useState(item.hidden);

    const [updateCategory, {isLoading: isCategoryUpdating}] = usePartialUpdateRouletteCategoryMutation();

    useEffect(() => {
        if (isEditFormDisplayed) {
            onEdit({item, isEditFormDisplayed})
        }
    }, [isEditFormDisplayed])

    useEffect(() => {
        setEditFormDisplayed(displayEditForm)
    }, [displayEditForm])

    const handleHiddenButton = (value) => {
        updateCategory({id: item.id, body: {id: item.id, hidden: value}})
            .then(() => setHideButtonDisplayed(value))
    }

    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        return (
            <Card key={item.id} background="dark-1" fill="false" style={{opacity: isHideButtonDisplayed ? 0.3 : 1}}>
                <CardHeader justify="end" gap="small" pad={{top: 'medium', left: 'medium', right: 'medium', bottom: 'none'}}>
                    <Box fill="horizontal">
                        <Text weight={800}>{item.categoryName}</Text>
                    </Box>
                    {isHideButtonDisplayed && (<Button size="medium" icon={<Hide/>} onClick={() => handleHiddenButton(false)}/>)}
                    {!isHideButtonDisplayed && (<Button size="medium" icon={<FormView/>} onClick={() => handleHiddenButton(true)}/>)}
                    <Button size="medium" icon={<SettingsOption/>} onClick={() => setEditFormDisplayed(true)}/>
                </CardHeader>
                <CardBody fill={false} pad={{top: 'small', left: 'medium', right: 'medium', bottom: 'medium'}}>
                    <Box direction="column" align="center" gap="small">
                        <Box fill="horizontal">
                            <Text weight={500} size="14px" color={'gray'}>{t('rewardNames')}</Text>
                            <Text>{item.rewardNames}</Text>
                        </Box>
                        <Box fill="horizontal">
                            <Text weight={500} size="14px" color={'gray'}>{t('rarity')}</Text>
                            <Text color={rarityOptions[item.rarity].color}>{rarityOptions[item.rarity].name}</Text>
                        </Box>
                        <Box fill="horizontal">
                            <Text weight={500} size="14px" color={'gray'}>{t('dropChance')}</Text>
                            <Text>{item.hidden ? 0 : dropChance}%</Text>
                        </Box>
                    </Box>
                </CardBody>
            </Card>
        )
    }
}