import { Button, Card, CardBody, CardFooter, Layer, Text } from "grommet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearLots } from "../../../slices/auctionDataSlice";

export const ClearTableDisalog = ({ show, onClose }) => {

    const { t } = useTranslation(['lots-table', 'wheel']);
    const dispatch = useDispatch();

    const handleClearButton = () => {
        dispatch(clearLots());
        onClose();
    }

    if (show) {
        return (
            <Layer
                onEsc={onClose}
                onClickOutside={onClose}
            >
                <Card height="small" width="medium" background="#222222">
                    <CardBody pad="medium" align="center" justify="center">
                        <Text size="medium">{t("clearLotsDialog")}</Text>
                    </CardBody>
                    <CardFooter pad="medium">
                        <Button
                            label={t("wheel:resetDialogCancel")}
                            onClick={onClose}
                        />
                        <Button
                            label={t("clearButton")}
                            primary
                            onClick={handleClearButton}
                        />
                    </CardFooter>
                </Card>
            </Layer>
        );
    }
    return null;
};
