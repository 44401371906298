import { TableRow, TableCell, Box, TextInput, Button, Text, Keyboard } from "grommet"
import { Add, Trash } from "grommet-icons"
import { forwardRef, memo, useState } from "react"
import { useDispatch } from "react-redux"
import { deleteLot, updateLot } from "../../slices/auctionDataSlice"

export const AuctionTableRow = memo(forwardRef(({ dataRow, index, ...props }, ref) => {

    const dispatch = useDispatch();

    const [cost, setCost] = useState(dataRow.extraCost);

    const onLotAdd = () => {
        let lot = {
            id: dataRow.id,
            lotName: dataRow.lotName,
            cost: dataRow.cost
        }
        if (cost && cost.length > 0) {
            dispatch(updateLot({...lot, ...{ index, extraCost: Number(cost) }}));
            setCost('');
        }
    }

    const onLotDelete = () => {
        dispatch(deleteLot({...dataRow, ...{ index }}));
    }

    return (
        <TableRow key={dataRow.id} ref={ref} {...props}>
            <TableCell size="xsmall" scope="row"><Text>{`${index + 1}.`}</Text></TableCell>
            <TableCell size="xsmall" scope="row">{dataRow.id}</TableCell>
            <TableCell size="medium" scope="row"><Text margin={'none'} truncate="tip">{dataRow.lotName}</Text></TableCell>
            <TableCell size="xsmall" scope="row">{Number(dataRow.chance * 100).toFixed(2)}%</TableCell>
            <TableCell size="medium" scope="row">
                <Box direction="row-responsive" gap="small" key={dataRow.id}>
                    <TextInput placeholder="UAH" value={dataRow.cost} />
                    <Keyboard onEnter={onLotAdd}>
                        <TextInput type="number" placeholder="₴" value={cost} onChange={(event) => setCost(event.target.value)}/>
                    </Keyboard>
                </Box>
            </TableCell>
            <TableCell size="small" scope="row">
                <Box direction="row" gap="small">
                    <Button icon={<Add/>} onClick={onLotAdd}/>
                    <Button icon={<Trash/>} onClick={onLotDelete}/>
                </Box>
            </TableCell>
        </TableRow>
    )
}))