import { Box, Button, Card, CardBody, Text } from "grommet"
import { useTranslation } from "react-i18next"

export const TwitchReward = ({id, name, cost, onRewardDelete, ...props}) => {

    const { t } = useTranslation('twitch-settings')

    return (
        <Box {...props}>
            <Card background="dark-1">
                <CardBody>
                    <Box direction="column" align="center" gap="small" margin={{bottom: 'medium'}}>
                        <Text truncate="tip" weight={'bold'}>{name}</Text>
                        <Text>{cost} ₴</Text>
                    </Box>
                    <Button label={t('rewardDeleteButton')} onClick={() => onRewardDelete(id)}/>
                </CardBody>
            </Card>
        </Box>
    )
}