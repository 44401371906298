import { Box, Button, CheckBox, FormField, RangeInput, Select, Text } from "grommet"
import { useEffect, useState } from "react"
import { useUpdateRouletteWidgetMutation } from "../../api/rouletteWidgetApi";
import { useTranslation } from "react-i18next";

export const WheelControls = ({rouletteWidgetSettings, wheelRef, isSpinning, onWheelSoundVolumeChange}) => {

    const {t, ready} = useTranslation('roulette', { useSuspense: false });

    const [isRouletteEnabled, setEnabled] = useState(rouletteWidgetSettings.enabled);
    const [wheelSoundVolume, setWheelSoundVolume] = useState(+rouletteWidgetSettings.volume);

    const [updateRouletteWidget] = useUpdateRouletteWidgetMutation();

    const handleEnabledToggle = (value) => {
        setEnabled(value);
        updateRouletteWidget({id: rouletteWidgetSettings.id, enabled: value});
    }

    const handleWheelSoundVolume = (value) => {
        setWheelSoundVolume(+value);
        onWheelSoundVolumeChange(+value);
    }

    const handleWheelVolumeSave = (value) => {
        updateRouletteWidget({id: rouletteWidgetSettings.id, volume: +value});
    }

    return (
        <Box>
            <Box margin={{top: 'medium'}}>
                <CheckBox 
                    pad={'none'}
                    toggle 
                    label={t('enableRoulette')}
                    checked={isRouletteEnabled}
                    onChange={(event) => handleEnabledToggle(event.target.checked)}
                />
            </Box>
            {/* <Box fill="horizontal" margin={{top: 'small'}}>
                <FormField label={t('wheelSound')}>
                    <Select
                        placeholder={t('wheelSoundPlaceholder')}
                        options={['test1']}
                        // value={wheelSound}
                        // onChange={setWheelSound}
                        // labelKey={(option) => option.name}
                    />
                </FormField>
            </Box> */}
            <Box fill="horizontal" pad={{top: 'medium', bottom: 'medium'}}>
                <Text weight={500} size="14px">{t('wheelSoundVolume')}</Text>
                <RangeInput 
                    value={wheelSoundVolume}
                    onChange={(event) => handleWheelSoundVolume(event.target.value)} 
                    onClickCapture={(event) => handleWheelVolumeSave(event.target.value)} 
                    min={0} max={1} step={0.1}
                />
            </Box>
            <Button label={t('spinButton')} primary onClick={() => wheelRef.current.spinWheel()} busy={isSpinning}/>
        </Box>
    )
}