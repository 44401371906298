import { Box, Button, Card, CardBody, CardHeader, ResponsiveContext, Text } from "grommet"
import { ResponsiveGrid } from "../ResponsiveGrid"
import { CircleInformation, Hide, SettingsOption } from "grommet-icons"
import { useContext, useEffect, useState } from "react";
import { RouletteItem } from "./RouletteItem";
import { useTranslation } from "react-i18next";

// if size if xsmall, we only 1 column
// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 2 columns with 2/3 1/3 proportions
const columns = {
    xsmall: ["auto"],
    small: ["auto"],
    medium: ["auto", "auto"],
    large: ["auto", "auto", "auto", "auto"],
    xlarge: ["auto", "auto", "auto", "auto"]
};

// if size if xsmall, we have 2 rows
// if size if small, we have 2 rows
// if size if medium, we have 2 rows
// if size if large or xlarge, we have 1 row
const rows = {
    xsmall: ["flex", "flex"],
    small: ["flex", "flex"],
    medium: ["flex"],
    large: ["flex", "flex"],
    xlarge: ["flex"]
};

const gap = {
    xsmall: 'medium',
    small: 'medium',
    medium: 'medium',
    large: 'medium',
    xlarge: 'medium'
}

const getTotalRarity = (items) => {
    return items.filter(item => !item.hidden)
        .reduce((partSum, item) => partSum + item.rarity, 0)
}

export const RouletteItems = ({items, onItemEdit, displayEditForm, setDisplayEditForm}) => {
    const { t, ready } = useTranslation('roulette'); 
    const size = useContext(ResponsiveContext);
    const [hasItems, setHasItems] = useState();

    useEffect(() => {
        setHasItems(items.length > 0)
    }, [items])

    return (
        <>
            {!hasItems && (
                <Box align="center" justify="center" height={'300px'}>
                    <CircleInformation/>
                    <Text margin={{top: 'small'}}>{t('noItems')}</Text>
                </Box>
            )}
            {hasItems && (
                <ResponsiveGrid
                    overrideRows={rows}
                    overrideColumns={columns}
                    gap={gap[size]}
                    margin="none"
                    fill={false}
                >
                    {items.map(item => (
                        <RouletteItem 
                            key={item.id}
                            item={item} 
                            dropChance={((item.rarity / getTotalRarity(items)) * 100).toFixed(1)} 
                            onEdit={({item, isEditFormDisplayed}) => {
                                const newDisplayEditForm = Object.assign({}, ...Object.keys(displayEditForm)
                                    .filter(key => key !== item.id)
                                    .map(key => { return {[key]: false} }));
                                newDisplayEditForm[item.id] = isEditFormDisplayed;
                                setDisplayEditForm(newDisplayEditForm);
                                onItemEdit({item, isEditFormDisplayed});
                            }}
                            displayEditForm={displayEditForm[item.id]}
                        />
                    ))}
                </ResponsiveGrid>
            )}
        </>
    )
}