import { Box, Button, Text } from "grommet"
import { FormDown, FormUp, Pause, Play, Stop } from "grommet-icons"
import { useEffect, useRef, useState } from "react"
import Countdown from "react-countdown"
import { useDispatch, useSelector } from "react-redux"
import { updateIsPaused, updateIsStarted } from "../../slices/timerSlice"

const DELAY = 60000 * 30;
export const DonatesTimer = () => {
    const isInitialMount = useRef(true);
    const [id, setId] = useState(0)
    const [date, setDate] = useState(() => {
        const savedDate = !isNaN(localStorage.getItem("timeLeft")) ? parseInt(localStorage.getItem("timeLeft"), 10) : null;
        if (savedDate != null && !isNaN(savedDate)) {
            const currentTime = Date.now();
            return {date: currentTime, delay: savedDate };
        }
        return { date: Date.now(), delay: DELAY }
    })
    const [timeLeft, setTimeLeft] = useState(() => !isNaN(localStorage.getItem("timeLeft")) ? parseInt(localStorage.getItem("timeLeft"), 10) : null);
    const wantedDelay = DELAY;
    const isStarted = useSelector(state => state.timer.isStarted)
    const isPaused = useSelector(state => state.timer.isPaused)
    const dispatch = useDispatch();
    const clockRef = useRef()

    const renderer = ({ milliseconds, formatted }) => {
        return (
            <Box justify="center" align="center">
                <Text size="4xl">{formatted.minutes}:{formatted.seconds}:{milliseconds === 0 ? '00' : milliseconds.toString().slice(0, 2)}</Text>
            </Box>
        );
    };
    const handleTimerStart = () => {
        clockRef.current.getApi().start();
    }
    const handleTimerPause = () => {
        clockRef.current.getApi().pause();
    }
    const handleTimerStop = () => {
        if (localStorage.getItem("timeLeft") !== null) {
            localStorage.removeItem("timeLeft");
        }
        if (localStorage.getItem("end_date") !== null) {
            localStorage.removeItem("end_date");
        }
        clockRef.current.getApi().stop();
        setDate({date: Date.now(), delay: DELAY});
        setTimeLeft(DELAY);
    }
    const handleTimerAdd = () => {
        let newTime = timeLeft + 60000
        setId(id + 1)
        setDate({date: Date.now(), delay: newTime});
        setTimeLeft(newTime)
    }
    const handleTimerSubstruct = () => {
        let newTime = (isNaN(timeLeft) ? date.delay : timeLeft) - 60000
        if (newTime <= 0) {
            newTime = 0;
        }
        setId(id + 1)
        setDate({date: Date.now(), delay: newTime});
        setTimeLeft(newTime)
    }
    useEffect(() => {
        if (!isPaused && isStarted) {
            const savedDate = localStorage.getItem("end_date");
            if (savedDate != null && !isNaN(savedDate)) {
                const currentTime = Date.now();
                const delta = parseInt(savedDate, 10) - currentTime;

                if (delta > wantedDelay) {
                    if (localStorage.getItem("end_date").length > 0) {
                        localStorage.removeItem("end_date");
                    }
                } else {
                    setDate({ date: currentTime, delay: delta });
                }
            }
        }
    }, []);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (!isNaN(timeLeft)) {
                localStorage.setItem("timeLeft", JSON.stringify(timeLeft));
                localStorage.setItem("end_date", JSON.stringify(Date.now() + timeLeft));
            }
        }
    }, [timeLeft])
    return (
        <Box>
            <Box>
                <Countdown
                    ref={clockRef}
                    key={id}
                    date={date.date + date.delay}
                    intervalDelay={0}
                    autoStart={isStarted}
                    zeroPadTime={2}
                    precision={2}
                    renderer={renderer}
                    onStart={() => {dispatch(updateIsStarted(true)); dispatch(updateIsPaused(false))}}
                    onPause={() => {dispatch(updateIsPaused(true)); dispatch(updateIsStarted(false))}}
                    onStop={() => {dispatch(updateIsPaused(true)); dispatch(updateIsStarted(false))}}
                    onComplete={() => {
                        dispatch(updateIsPaused(true));
                        dispatch(updateIsStarted(false));
                        setId(id + 1)
                        setDate({date: Date.now(), delay: 0})
                        if (localStorage.getItem("timeLeft") != null) {
                            localStorage.removeItem("timeLeft");
                        }
                        if (localStorage.getItem("end_date") != null) {
                            localStorage.removeItem("end_date");
                        }
                    }}
                    onTick={props => setTimeLeft(props.total)}
                />
            </Box>
            <Box direction="row" justify="around">
                <Button icon={<Play/>} style={{display: isStarted || !isPaused ? 'none' : 'inline-block'}} onClick={handleTimerStart}/>
                <Button icon={<Pause/>} style={{display: isStarted ? 'inline-block' : 'none'}} onClick={handleTimerPause}/>
                <Button icon={<Stop/>} onClick={handleTimerStop}/>
                <Button icon={<FormUp/>} onClick={handleTimerAdd}/>
                <Button icon={<FormDown/>} onClick={handleTimerSubstruct}/>
            </Box>
        </Box>
    )
}