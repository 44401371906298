import { BoxWindow } from "../components/BoxWindow"
import { DonatelloSettings } from "../components/auc-settings/DonatelloSettings"
import { DonatikSettings } from "../components/auc-settings/DonatikSettings"

export const SettingsPage = () => {

    return (
        <BoxWindow topPadding>
            <DonatelloSettings/>
            <DonatikSettings/>
        </BoxWindow>
    )
}