import { Component } from "react";
import { DroppableAuctionTableRow } from "./DroppableAuctionTableRow";

/**
 * This component is wrapper for functional component of AuctionTableRow.
 * Is needed for FlipMove library in order to have ref
 * because ref in functional component is used by Drag and Drop lib
 */
class AuctionTableRowClass extends Component {

    constructor(props) {
        super(props);
        this.props = props;
    }
    
    render () {
        return (
            <DroppableAuctionTableRow
                key={this.props.dataRow.id}
                dataRow={this.props.dataRow}
                index={this.props.index}
            />
        )
    }
}

export default AuctionTableRowClass;