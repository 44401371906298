import { createSlice } from "@reduxjs/toolkit";

export const timerSlice = createSlice({
    name: 'timer',
    initialState: {
        isStarted: false,
        isPaused: true
    },
    reducers: {
        updateIsStarted: (state, action) => {
            state.isStarted = action.payload;
        },
        updateIsPaused: (state, action) => {
            state.isPaused = action.payload;
        }
    }
})

export const { updateIsStarted, updateIsPaused } = timerSlice.actions;

export default timerSlice.reducer;