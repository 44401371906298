import { useContext } from 'react';
import { Box, ResponsiveContext, Card, CardBody } from 'grommet';
import { ResponsiveGrid } from '../ResponsiveGrid';

// if size if xsmall, we only 1 column
// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 2 columns with 2/3 1/3 proportions
const columns = {
    xsmall: ["auto"],
    small: ["auto"],
    medium: ["auto"],
    large: ["2/3", "1/3"],
    xlarge: ["2/3", "1/3"]
  };
  
  // if size if xsmall, we have 2 rows
  // if size if small, we have 2 rows
  // if size if medium, we have 2 rows
  // if size if large or xlarge, we have 1 row
  const rows = {
    xsmall: ["flex", "flex"],
    small: ["flex", "flex"],
    medium: ["flex", "flex"],
    large: ["flex"],
    xlarge: ["flex"]
  };

  // set the different areas you need for every size
const areas = {
    xsmall: [
        ['mainArea'],
        ['settingsArea']
    ],
    small: [
        ['mainArea'],
        ['settingsArea']
    ],
    medium: [
        ['mainArea'],
        ['settingsArea']
    ],
    large: [
        ['mainArea', 'settingsArea']
    ],
    xlarge: [
        ['mainArea', 'settingsArea']
    ]
};

const gap = {
    xsmall: 'medium',
    small: 'medium',
    medium: 'medium',
    large: 'none',
    xlarge: 'none'
}

const paddingsRight = {
    xsmall: {},
    small: {},
    medium: {},
    large: {right: "small"},
    xlarge: {right: "small"}
}

const paddingsLeft = {
    xsmall: {},
    small: {},
    medium: {},
    large: {left: "small"},
    xlarge: {left: "small"}
}

export const RouletteGrid = ({leftCard, rightCard, hidden, leftAlign, ...props}) => {
    const size = useContext(ResponsiveContext);

    return (
        <ResponsiveGrid 
            style={{display: hidden ? 'none' : 'grid'}}
            {...props}
            overrideRows={rows}
            overrideColumns={columns}
            areas={areas}
            gap={gap[size]}
        >
            <Box gridArea='mainArea' pad={paddingsRight[size]} fill>
                <Card background="#222222" fill>
                  <CardBody pad="medium" fill justify={leftAlign}>{leftCard}</CardBody>
                </Card>
            </Box>
            <Box gridArea='settingsArea' pad={paddingsLeft[size]} fill>
                <Card background="#222222" fill>
                  <CardBody pad="medium" fill>{rightCard}</CardBody>
                </Card>
            </Box>
        </ResponsiveGrid>
    );
};