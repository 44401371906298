import { useDispatch } from "react-redux";
import { removeLot } from "../../../slices/wheelDataSlice";
import { Trans, useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardFooter, Layer, Text } from "grommet";

export const DropoutDialog = ({ droppedOutSector, show, onClose }) => {

    const { t } = useTranslation(['lots-table', 'wheel'], { useSuspense: false });

    const dispatch = useDispatch();

    const handleWinningSectorRemove = () => {
        dispatch(removeLot(droppedOutSector));
        onClose();
    }

    if (show) {
        return (
            <Layer onClickOutside={() => handleWinningSectorRemove()}>
                <Card height={{min: 'small'}} width={{min: 'medium'}} background="#222222">
                <CardBody pad="medium" align="center" justify="center">
                    <Text size="medium">
                        <Trans i18nKey="wheel:eliminationDialogText" values={{lotName: droppedOutSector.lotName}} components={{text: <Text weight={'bold'}/>}}/>
                    </Text>
                </CardBody>
                <CardFooter pad="medium" justify="end">
                    <Button label={t('wheel:nextButton')} primary onClick={() => handleWinningSectorRemove()}/>
                </CardFooter>
                </Card>
            </Layer>
        )
    }
}