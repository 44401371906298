import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/users`, credentials: 'include' }),
    tagTypes: ['User', 'Reward'],
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => `/current`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: ['User'],
        }),
        partialUserUpdate: builder.mutation({
            query: (body) => ({url: `/current`, method: 'PATCH', body: body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            invalidatesTags: ['User'],
        }),
        getUserDonates: builder.query({
            query: () => ({url: `/current/donates`}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status
        }),
        getUserRewards: builder.query({
            query: () => ({url: `/current/rewards`}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: ['Reward']
        }),
        createUserReward: builder.mutation({
            query: (body) => ({url: `/current/rewards`, method: 'POST', body: body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Reward']
        }),
        deleteUserReward: builder.mutation({
            query: (id) => {
                return {url: `/current/rewards/${id}`, method: 'DELETE'}
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Reward']
        }),
        logoutUser: builder.mutation({
            query: () => {
                return {url: `/current/logout`, method: 'POST'}
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['User']
        })
    }),
})

export const { useGetCurrentUserQuery, useLazyGetUserDonatesQuery, useGetUserRewardsQuery, usePartialUserUpdateMutation, useCreateUserRewardMutation, useDeleteUserRewardMutation, useLogoutUserMutation } = userApi