import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const emotesApi = createApi({
    reducerPath: 'emotesApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/emotes`, credentials: 'include' }),
    endpoints: (builder) => ({
        getCurrentUserEmotes: builder.query({
            query: () => `/users/current`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status
        }),
        getGlobalTwitchEmotes: builder.query({
            query: () => `/global`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status
        })
    }),
})

export const { useGetCurrentUserEmotesQuery, useGetGlobalTwitchEmotesQuery } = emotesApi