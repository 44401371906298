import { Grommet, Spinner } from "grommet";
import { deepMerge } from "grommet/utils";
import { hpe } from 'grommet-theme-hpe';

import { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RoulettePage } from "./pages/RoulettePage";
import { AuthCallback } from "./components/auth/AuthCallback";
import { RouletteWidgetPage } from "./pages/RouletteWidgetPage";
import { HomePage } from "./pages/HomePage";
import { SettingsPage } from "./pages/SettingsPage";
import { AuctionPage } from "./pages/AuctionPage";

function App() {

  const mainColor = "#a6025c";
  const hoverColor = "#87004a";

  const theme = deepMerge(hpe, {
    global: {
      font: {
        face: {
          'font-family': 'Roboto Mono',
          family: 'monospace'
        }
      },
      colors: {
        focus: mainColor,
        brand: mainColor,
        "green!": mainColor,
        "green": {
          "dark": hoverColor,
          "light": hoverColor
        }
      }
    },
    table: {
      header: {
        pad: { left: '12px', right: '12px', top: '6px', bottom: '6px' },
        verticalAlign: 'bottom',
        fill: 'vertical',
        border: 'bottom',
        align: 'start'
      }
    },
    formField: {
      extend: ({ noBorder }) =>
        noBorder &&
          `
            > label + div {
              border: none;
              > div {
                padding: 0;
              }
            }
          `
    },
  });

  return (
    <Grommet background={'transperent'} theme={theme} full themeMode="dark">
      <Suspense fallback={<Spinner/>}>
      </Suspense>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<HomePage/>}>
              <Route index element={<AuctionPage/>}/>
              <Route path="roulette" element={<RoulettePage/>}/>
              <Route path="callback" element={<AuthCallback/>}/>
              <Route path="settings" element={<SettingsPage/>}/>
              <Route path="*" element={<Navigate to="/" />}/>
            </Route>
            <Route path="/widgets">
              <Route path="roulette/:rouletteId" element={<RouletteWidgetPage/>} />
            </Route>
        </Routes>
      </BrowserRouter>
    </Grommet>
  );
}

export default App;
