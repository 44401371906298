import { useEffect } from "react";
import { useLazyTwitchCodeAuthQuery } from "../../api/authApi";
import { Navigate } from "react-router-dom";
import { useQuery } from "../../hooks/useQuery";
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../../slices/authSlice";

export const AuthCallback = () => {

    const dispatch = useDispatch();
    const query = useQuery();

    const [twitchCodeAuth] = useLazyTwitchCodeAuthQuery();

    useEffect(() => {
        const code = query.get('code');
        const scope = query.get('scope');
        const state = query.get('state');
        twitchCodeAuth({code, scope, state})
            .then(
                (result) => dispatch(setAuthenticated(true)),
                (error) => console.log('Auth error')
            )
    }, [query])

    return (
        <Navigate to="/" />
    )
}