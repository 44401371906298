import { Box, Button, Keyboard, Menu, Text, TextInput, Toolbar } from "grommet";
import { Add, MoreVertical, Refresh, Save, Search, Trash, Upload } from "grommet-icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addLot, resetLotCosts, saveItemsToFile, setSearchTerm } from "../../slices/auctionDataSlice";
import { ClearTableDisalog } from "./dialog/ClearTableDialog";
import { UploadDialog } from "./dialog/UploadDialog";

export const AuctionTableToolbar = () => {
    
    const { t } = useTranslation('lots-table');
    const dispatch = useDispatch();

    const [lotForm, setLotForm] = useState({
        lotName: '',
        cost: ''
    });
    const [searchValue, setSearchValue] = useState('');
    const [showClearDataDialog, setShowClearDataDialog] = useState(false);
    const [showUploadDialog, setShowUloadDialog] = useState(false);

    const onSaveToFileClick = () => {
        dispatch(saveItemsToFile());
    }

    const onUploadClick = () => {
        setShowUloadDialog(true);
    }

    const onClearDataClick = () => {
        setShowClearDataDialog(true);
    }

    const onResetClick = () => {
        dispatch(resetLotCosts())
    }

    const moreMenuItems = [
        {
            label: <Text weight={'bold'}>{t('saveToFileButton')}</Text>,
            icon: <Save/>,
            onClick: () => onSaveToFileClick(),
        }, 
        {
            label: <Text weight={'bold'}>{t('uploadButton')}</Text>,
            icon: <Upload/>,
            onClick: () => onUploadClick(),
        }, 
        {
            label: <Text weight={'bold'}>{t('clearButton')}</Text>,
            icon: <Trash/>,
            onClick: () => onClearDataClick(),
        }, 
        {
            label: <Text weight={'bold'}>{t('resetToOneButton')}</Text>,
            icon: <Refresh/>,
            onClick: () => onResetClick(),
        },
    ]

    const handleLotNameInput = (event) => {
        const value = event.target.value;
        setLotForm({...lotForm, ...{ lotName: value }});
    }

    const handleCostInput = (event) => {
        const value = event.target.value;
        setLotForm({...lotForm, ...{ cost: value }});
    }

    const handleCreateLotClick = () => {
        lotForm.lotName = lotForm.lotName.trim();
        if (lotForm.lotName.length > 0 && Number(lotForm.cost) > 0) {
            lotForm.cost = Number(lotForm.cost);
            dispatch(addLot(lotForm));
            setLotForm({ lotName: '', cost: '' });
        }
    }

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchValue(value.trim());
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(setSearchTerm(searchValue));
        }, 250)
        return () => clearTimeout(delayDebounceFn)
      }, [searchValue])

    return (
        <Box>
            <Toolbar margin={{bottom: "medium"}} alignContent="start">
                <TextInput placeholder={t('lotInputPlaceholder')} value={lotForm.lotName} onChange={handleLotNameInput}/>
                <Keyboard onEnter={handleCreateLotClick}>
                    <TextInput placeholder="₴" type="number" min={1} value={lotForm.cost} onChange={handleCostInput}/>
                </Keyboard>
                <Button icon={<Add/>} fill="horizontal" primary label={t('lotSubmitButton')} onClick={handleCreateLotClick}/>
                <Menu icon={<MoreVertical/>} items={moreMenuItems} dropAlign={{left: 'left', top: 'bottom'}}/>
            </Toolbar>
            <Box margin={{bottom: "medium"}}><TextInput name="_search" icon={<Search />} type="search" onChange={handleSearch}/></Box>
            <ClearTableDisalog show={showClearDataDialog} onClose={() => setShowClearDataDialog(false)} />
            <UploadDialog show={showUploadDialog} onClose={() => setShowUloadDialog(false)} />
        </Box>
    )
}