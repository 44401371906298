import { Box, Button, CheckBox, FormField, Grid, Image, Markdown, RangeInput, Select, Spinner, Text, TextInput } from "grommet"
import { Clear } from "grommet-icons";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useGetCurrentUserEmotesQuery } from "../../api/emotesApi";
import { useDispatch, useSelector } from "react-redux";
import { authenticationSelector } from "../../slices/authSlice";
import { FormFieldWithTip } from "../../forms/FormFieldWithTip";
import { setEmote, setShowChances, setSpinStarted, setSupergameCostThreshold, setSupergameEnabled, setWheelDuration, setWheelSize, setWheelSound, setWheelSoundVolume, setWheelSpinMode, setWheelTheme } from "../../slices/wheelSettingsSlice";
import { WheelResetDialog } from "../wheel/dialog/WheelResetDialog";

const emotes = [
    'https://cdn.frankerfacez.com/emoticon/128054/4',
    'https://cdn.frankerfacez.com/emoticon/231552/4',
    'https://cdn.frankerfacez.com/emoticon/381875/4',
    'https://cdn.frankerfacez.com/emoticon/240746/4',
    'https://cdn.frankerfacez.com/emoticon/191854/4',
    'https://cdn.frankerfacez.com/emoticon/201362/4',
    'https://cdn.frankerfacez.com/emoticon/390750/4',
    'https://cdn.frankerfacez.com/emoticon/307828/4',
    'https://cdn.frankerfacez.com/emoticon/637650/4',
    'https://cdn.frankerfacez.com/emoticon/57611/4',
    'https://cdn.frankerfacez.com/emoticon/240443/4',
    'https://cdn.betterttv.net/emote/56e9f494fff3cc5c35e5287e/3x.webp',
    'https://cdn.betterttv.net/emote/60f31ca72d1eba5400d01288/3x.webp',
    'https://cdn.betterttv.net/emote/63781712b9076d0aaebc6c33/3x.webp',
    'https://cdn.betterttv.net/emote/5e8c5bf709989e5cdff55a20/3x.webp',
    'https://cdn.betterttv.net/emote/5c69f5caadab351034b3fcc1/3x.webp',
    'https://cdn.betterttv.net/emote/5fb177eeeca18f6455c345f9/3x.webp',
    'https://cdn.betterttv.net/emote/560577560874de34757d2dc0/3x.webp',
    'https://cdn.betterttv.net/emote/5c857788f779543bcdf37124/3x.webp',
    'https://cdn.betterttv.net/emote/5f1b0186cf6d2144653d2970/3x.webp',
    'https://cdn.betterttv.net/emote/5f43037db2efd65d77e8a88f/3x.webp',
    'https://cdn.betterttv.net/emote/63e324367b3e63779e664a5c/3x.webp',
    'https://cdn.betterttv.net/emote/60427e74306b602acc597851/3x.webp',
    'https://cdn.betterttv.net/emote/5f22775dcf6d2144653d96ce/3x.webp',
    'https://cdn.betterttv.net/emote/5ab6f0ece1d6391b63498774/3x.webp',
    'https://cdn.betterttv.net/emote/639ba65c91ab7f35abdfa25d/3x.webp',
    'https://cdn.betterttv.net/emote/5e5300e6751afe7d553e4351/3x.webp',
    'https://cdn.betterttv.net/emote/5e8507e08b4f04096c5a7b18/3x.webp',
    'https://cdn.betterttv.net/emote/611d056876ea4e2b9f77ebdd/3x.webp',
    'https://cdn.betterttv.net/emote/608ac06139b5010444d087c6/3x.webp',
    'https://cdn.betterttv.net/emote/6055c431306b602acc5a0622/3x.webp',
    'https://cdn.betterttv.net/emote/5a2649148ae82f4786054b9c/3x.webp',
    'https://cdn.betterttv.net/emote/5cfc4da067398f2d6a1fe931/3x.webp',
    'https://cdn.betterttv.net/emote/5e66999cd6581c3724c0256e/3x.webp',
    'https://cdn.betterttv.net/emote/5f0435ed6bd7f3052a0c5564/3x.webp',
    'https://cdn.betterttv.net/emote/55fa107e6d3be5b57c727e8d/3x.webp',
    'https://cdn.betterttv.net/emote/57320689d69badf9131b82c4/3x.webp',
    'https://cdn.betterttv.net/emote/5a9578d6dcf3205f57ba294f/3x.webp',
    'https://cdn.betterttv.net/emote/589e5f0506e70d0465b27849/3x.webp',
    'https://cdn.betterttv.net/emote/640fc68c746c2603905a132b/3x.webp'
]

const getLangKey = (i18n) => {
    if (['en', 'uk'].includes(i18n.language)) {
        return i18n.language;
    }
    return 'en';
}

export const WheelSettings = ({ wheelRef, isSpinning }) => {
    const { t, i18n, ready } = useTranslation('wheel', { useSuspense: false })
    const [spinModes, setSpinModes] = useState([
        { en: t('wheel:spinMode1', { lng: 'en' }), uk: t('wheel:spinMode1', { lng: 'uk' }), mode: 1},
        { en: t('wheel:spinMode2', { lng: 'en' }), uk: t('wheel:spinMode2', { lng: 'uk' }), mode: 2},
    ])
    const [wheelThemes, setWheelThemes] = useState([
        { name: { en: t('wheel:mainTheme', { lng: 'en' }), uk: t('wheel:mainTheme', { lng: 'uk' }) }, themeKey: 'classic' },
        { name: { en: t('wheel:christmasTheme', { lng: 'en' }), uk: t('wheel:christmasTheme', { lng: 'uk' }) }, themeKey: 'christmas' },
        { name: { en: t('wheel:noneTheme', { lng: 'en' }), uk: t('wheel:noneTheme', { lng: 'uk' }) }, themeKey: 'minimalistic' }
    ])

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(authenticationSelector);
    const { data: userTwitchEmotes, isError: isUserTwitchEmotesError, isSuccess: isUserTwitchEmotesSuccess } = useGetCurrentUserEmotesQuery(undefined, { skip: !isAuthenticated });
    const [showResetDialog, setShowResetDialog] = useState(false);
    
    const [twitchEmotes, setTwitchEmotes] = useState();

    const { spinStarted,
            wheelDuration,
            wheelSpinMode,
            supergameEnabled,
            supergameCostThreshold,
            wheelTheme,
            showChances,
            wheelSize,
            wheelSound,
            wheelSounds,
            wheelSoundVolume } = useSelector(state => state.wheelSettings);

    const columns = [
        ['small'],
        ['small', 'small']
    ]
    const rows = [
        ['flex', 'flex', 'flex', 'flex']
    ]

    useEffect(() => {
        if (isUserTwitchEmotesSuccess) {
            const data = userTwitchEmotes.data;
            const template = userTwitchEmotes.template;
            setTwitchEmotes(data.map(emote => template.replace('{{id}}', emote.id).replace('{{format}}', emote.format.length == 2 ? emote.format[1] : emote.format[0]).replace('{{theme_mode}}', emote.theme_mode[1]).replace('{{scale}}', emote.scale[2])))
        }
    }, [isUserTwitchEmotesSuccess])

    const onSpinWheel = () => {
        wheelRef.current.spinWheel();
        if (wheelSpinMode == 2) {
            dispatch(setSpinStarted(true));
        }
    }

    const onWheelDurationChange = (event) => {
        const duration = event.target.value;
        dispatch(setWheelDuration(duration));
    }

    const onWheelSpinModeChange = (option) => {
        dispatch(setWheelSpinMode(option.value.mode))
        if (option.value.mode == 1) {
            dispatch(setSupergameEnabled(false));
        }
    }

    const onSupergameEnabled = (event) => {
        dispatch(setSupergameEnabled(event.target.checked));
    }

    const onSupergameCostThreshold = (event) => {
        const threshold = event.target.value;
        dispatch(setSupergameCostThreshold(threshold));
    }

    const onWheelTheme = (option) => {
        dispatch(setWheelTheme(option.value.themeKey));
    }

    const onShowChances = (event) => {
        dispatch(setShowChances(event.target.checked));
    }

    const onWheelSize = (event) => {
        dispatch(setWheelSize(event.target.value));
    }

    const onWheelSound = (option) => {
        dispatch(setWheelSound(option.value));
    }

    const onWheelSoundVolume = (event) => {
        const volume = event.target.value;
        dispatch(setWheelSoundVolume(volume));
    }

    const onEmote = (emote) => {
        dispatch(setEmote(emote));
    }

    const onResetButton = () => {
        setShowResetDialog(true);
    }

    if (!ready) {
        return (<Spinner/>)
    }

    return (
        <Grid
            columns={columns}
            rows={rows}
            responsive={true}
            fill="horizontal"
        >
            <Box fill="horizontal" pad={{bottom: 'small'}}>
                <FormField label={t('spinDuration')}>
                    <TextInput
                        placeholder={t('spinDurationPlaceholder')}
                        value={wheelDuration}
                        onChange={onWheelDurationChange}
                        type="number"
                    />
                </FormField>
            </Box>
            <Box fill="horizontal" pad={{bottom: 'small'}}>
                <Button primary label={t('spinButton')} onClick={onSpinWheel} busy={isSpinning}/>
            </Box>
            <Box fill="horizontal">
                <FormFieldWithTip label={t('spinMode')} tip={<Markdown>{t('spinModeTip')}</Markdown>}>
                    <Select
                        disabled={spinStarted || isSpinning}
                        placeholder={t('spinModePlaceholder')}
                        options={spinModes}
                        value={spinModes.filter(spinMode => spinMode.mode === wheelSpinMode)}
                        labelKey={`${getLangKey(i18n)}`}
                        onChange={onWheelSpinModeChange}
                    />
                </FormFieldWithTip>
            </Box>
            <Box fill="horizontal" style={{display: wheelSpinMode == 2 ? 'flex' : 'none'}} margin={{top: 'small', left: 'none', right: 'none', bottom: 'none'}}>
                <CheckBox disabled={spinStarted || isSpinning} pad={'none'} toggle checked={supergameEnabled} label={t('enableSupergame')} onChange={onSupergameEnabled}/>
                <FormField disabled={spinStarted || isSpinning} label={t('supergameLotCost')} style={{display: supergameEnabled ? 'flex' : 'none'}}>
                    <TextInput
                        disabled={spinStarted || isSpinning}
                        placeholder={t('supergameLotCostPlaceholder')}
                        value={supergameCostThreshold}
                        onChange={onSupergameCostThreshold}
                        type="number"
                    />
                </FormField>
            </Box>
            <Box fill="horizontal" margin={{top: 'small'}}>
                <Button label={t('resetButton')} secondary onClick={onResetButton} disabled={isSpinning}/>
            </Box>
            <Box fill="horizontal" margin={{top: 'small'}}>
                <FormField label={t('wheelTheme')}>
                    <Select
                        placeholder={t('wheelThemePlaceholder')}
                        options={wheelThemes}
                        value={wheelThemes.filter(theme => theme.themeKey === wheelTheme)}
                        onChange={onWheelTheme}
                        labelKey={(option) => option.name[getLangKey(i18n)]}
                    />
                </FormField>
            </Box>
            <Box fill="horizontal" margin={{top: 'small'}}>
                <CheckBox pad={'none'} toggle label={t('showChances')} checked={showChances} onChange={onShowChances} />
            </Box>
            <Box fill="horizontal" pad={{top: 'small'}}>
                <Text weight={500} size="14px">{t('wheelSize')}</Text>
                <RangeInput value={wheelSize} onChange={onWheelSize} min={48} max={1536}/>
            </Box>
            <Box fill="horizontal">
                <FormField label={t('wheelSound')}>
                    <Select
                        placeholder={t('wheelSoundPlaceholder')}
                        options={wheelSounds}
                        value={wheelSound}
                        onChange={onWheelSound}
                        labelKey={(option) => option.name}
                    />
                </FormField>
            </Box>
            <Box fill="horizontal" margin={{top: 'small'}}>
                <Text weight={500} size="14px">{t('wheelSoundVolume')}</Text>
                <RangeInput value={wheelSoundVolume} onChange={onWheelSoundVolume} min={0} max={1} step={0.1}/>
            </Box>
            <Box fill="horizontal" margin={{top: 'small'}} height={'medium'} overflow={'scroll'}>
                <Grid columns={'xxsmall'} responsive gap={'small'}>
                    <Box align="center" justify="center" hoverIndicator={{background: {color: 'background-contrast'},elevation: 'medium'}} onClick={() => onEmote(null)} height={'xxsmall'} width={'xxsmall'}><Clear size="xxlarge"/></Box>
                    {emotes.map(emote => 
                        (<Box hoverIndicator={{background: {color: 'background-contrast'},elevation: 'medium'}} onClick={() => onEmote(emote)} height={'xxsmall'} width={'xxsmall'}><Image fit="contain" src={emote}/></Box>)
                    )}
                </Grid>
                {twitchEmotes && (
                    <Grid pad={{top: 'small'}} columns={'xxsmall'} responsive gap={'small'}>
                        {twitchEmotes.map(emote => 
                            (<Box hoverIndicator={{background: {color: 'background-contrast'},elevation: 'medium'}} onClick={() => onEmote(emote)} height={'xxsmall'} width={'xxsmall'}><Image fit="contain" src={emote}/></Box>)
                        )}
                    </Grid>
                )}
            </Box>
            <WheelResetDialog show={showResetDialog} onClose={() => setShowResetDialog(false)} />
        </Grid>
    )
}