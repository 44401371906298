import FlipMove from "react-flip-move"
import { memo } from "react"
import { InfiniteScroll, TableBody } from "grommet";
import { DroppableAuctionTableRow } from "./DroppableAuctionTableRow";
import AuctionTableRowClass from "./AuctionTableRowClass";


const AuctionTableRows = (({ data }) => {
    return (
        <FlipMove typeName="tbody" style={{display: 'table-row-group', verticalAlign: 'middle', borderColor: 'inherit'}}>
            {data.map((dataRow, index) => (
                <AuctionTableRowClass
                    key={dataRow.id}
                    dataRow={dataRow}
                    index={index}
                />
            ))}
        </FlipMove>
    )
})

const OptimizedAuctionTableRows = (({ data }) => {
    return (
        <TableBody>
            <InfiniteScroll items={data} replace={true}>
                {(dataRow, index) => (
                    <DroppableAuctionTableRow
                        key={dataRow.id}
                        dataRow={dataRow}
                        index={index}
                    />
                )}
            </InfiniteScroll>
        </TableBody>
    )
})

export const AuctionTableBody = memo(({ data }) => {

    if (data.length > 100) {
        return (
            <OptimizedAuctionTableRows data={data}/>
        )
    }
    return (
        <AuctionTableRows data={data} />
    )
})