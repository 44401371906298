import { createSlice } from "@reduxjs/toolkit";
import { dbAdapter } from "../db";
import { divide, subtract } from "mathjs";

const loadWheelData = async (wheelMode) => {
	let wheelData = await dbAdapter.loadAuctionData('wheelData');
	let totalCost = wheelData.reduce((accumulator, currentItem) => accumulator + currentItem.cost, 0);
	if (wheelMode === wheelModes.SIMPLE_MODE) {
        wheelData = calculateChances(wheelData, totalCost);
        return { wheelData, totalCost };
    } else if (wheelMode === wheelModes.DROPOUT_MODE) {
        wheelData = calculateChancesForDropout(wheelData, totalCost);
        return { wheelData, totalCost };
    }
	return { wheelData, totalCost };
};

const calculateChances = (items, totalCost) => {
    return items.map((row) => ({...row, ...{ chance: row.cost / totalCost }}));
}

const calculateChancesForDropout = (items, totalCost) => {
    return items.map((row) => {
        const chance = divide(subtract(1, divide(row.cost, totalCost)), items.length > 1 ? subtract(items.length, 1) : 1);
        if (chance > 0) {
            return {...row, ...{chance: chance}}
        }
        return {...row, ...{chance: 1}}
    })
}

export const wheelModes = {
    SIMPLE_MODE: 'simple',
    DROPOUT_MODE: 'dropout'
}

const loadWheelMode = () => {
    return localStorage.getItem('wheel-mode') ? localStorage.getItem('wheel-mode') : wheelModes.SIMPLE_MODE;
}

const loadNotLocked = () => {
    return localStorage.getItem('notLocked') ? JSON.parse(localStorage.getItem('notLocked')) : true;
}

const wheelMode = loadWheelMode();

const notLocked = loadNotLocked();

const { wheelData, totalCost } = await loadWheelData(wheelMode);

export const wheelDataSlice = createSlice({
    name: 'wheelData',
    initialState: {
        wheelData: wheelData,
        totalCost: totalCost,
        wheelMode: wheelMode,
        isNotLocked: notLocked,
    },
    reducers: {
        setWheelData: (state, action) => {
            if (state.isNotLocked) {
                state.wheelData = action.payload;
                state.totalCost = action.payload.reduce((accumulator, currentItem) => accumulator + currentItem.cost, 0);
            }
        },
        setWheelMode: (state, action) => {
            state.wheelMode = action.payload;
        },
        setNotLocked: (state, action) => {
            state.isNotLocked = action.payload;
        },
        removeLot: (state, action) => {
            const lot = action.payload;
            state.wheelData = state.wheelData.filter(row => row.id !== lot.id);
            state.totalCost -= lot.cost;
            state.isNotLocked = false;
        }
    }
})

export const { setWheelData, setWheelMode, setNotLocked, removeLot } = wheelDataSlice.actions;

export default wheelDataSlice.reducer;