import { Grid, ResponsiveContext } from "grommet";

export const ResponsiveGrid = ({
    children,
    overrideColumns,
    overrideRows,
    areas,
    ...props
}) => (
<ResponsiveContext.Consumer>
    {size => {
        // take into consideration if not array is sent but a simple string
        let columnsVal = overrideColumns;
        if (overrideColumns) {
            if (overrideColumns[size]) {
                columnsVal = overrideColumns[size];
            }
        }

        let rowsVal = overrideRows;
        if (overrideRows) {
            if (overrideRows[size]) {
                rowsVal = overrideRows[size];
            }
        }

        // also if areas is a simple array not an object of arrays for different sizes
        let areasVal = areas;
        if (areas && !Array.isArray(areas)) areasVal = areas[size];

        return (
            <Grid
                {...props}
                areas={!areasVal ? undefined : areasVal}
                rows={!rowsVal ? size : rowsVal}
                columns={!columnsVal ? size : columnsVal}
                responsive={true}
            >
                {children}
            </Grid>
        );
    }}
</ResponsiveContext.Consumer>
);