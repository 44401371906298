import {
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import { divide } from "mathjs";
import { dbAdapter } from "../db";
import { Parser } from "@json2csv/plainjs";

const loadData = async () => {
	let auctionData = await dbAdapter.loadAuctionData('auctionData');
	let totalCost = auctionData.reduce((accumulator, currentItem) => accumulator + currentItem.cost, 0);
	auctionData = auctionData.map(row => ({ ...row, ...{ chance: divide(row.cost, totalCost), extraCost: null } }));
	return { auctionData, totalCost };
};

const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const result = [];

    for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim().replaceAll('\"', '')] = currentLine[j].trim().replaceAll('\"', '');
        }

        result.push(obj);
    }

    return result;
};

export const uploadAuctionData = createAsyncThunk(
	"auctionData/uploadAuctionData",
	 async (value, { dispatch, getState }) => {
		const reader = new FileReader();

        reader.onload = async (e) => {
          const csvData = e.target.result;
          const jsonData = convertCSVToJson(csvData)
		  const data = jsonData.map(row => ({ lotName: row.lotName, cost: Number(row.cost) }))
          if (!value.merge) {
        	dispatch(clearLots());
        	dispatch(addLots(data));
          }
          if (value.merge) {
			data.forEach(async row => {
				const { auctionData } = getState();
				const existingLotIndex = Object.values(auctionData.items).findIndex(item => item.lotName === row.lotName);
				if (existingLotIndex !== -1) {
					const mergeLot = auctionData.items[existingLotIndex];
					dispatch(updateLot({...mergeLot, ...{ index: existingLotIndex, extraCost: row.cost }}))
				} else {
					dispatch(addLot(row))
				}
			})
          }
        };
    
        reader.readAsText(value.fileData);
	 }
);

const { auctionData, totalCost } = await loadData();

const getId = () => {
	const id = localStorage.getItem("id");
	if (id != undefined) {
		return id;
	}
	return 1;
}

let id = getId();

export const auctionDataSlice = createSlice({
	name: "auctionData",
	initialState: {
		items: auctionData,
		totalCost: totalCost,
		searchTerm: "",
	},
	reducers: {
		addLot: {
			reducer: (state, action) => {
				const item = action.payload;
				if (state.items.length > 0 && item.cost > state.items[0].cost) {
					state.items.unshift(item);
				} else {
					state.items.push(item);
				}
				state.totalCost += item.cost;
				localStorage.setItem("id", id);
			},
			prepare: (item) => {
				return { payload: {...item, ...{ id: id++ }} }
			}
		},
		addLots: {
			reducer: (state, action) => {
				const items = action.payload;
				state.items = items;
				state.totalCost += items.reduce((accumulator, currentItem) => accumulator + currentItem.cost, 0);
				localStorage.setItem("id", id);
			},
			prepare: (items) => {
				return { payload: items.map(item => ({...item, ...{ id: id++ }})) }
			}
		},
		updateLot: {
			reducer: (state, action) => {
				const item = action.payload;
				// find real index if in search
				if (state.searchTerm.length >= 3) {
					const index = state.items.findIndex((row) => row.id === item.id);
					item.index = index;
				}
				if (item.cost > state.items[0].cost) {
					state.items.splice(item.index, 1);
					state.items.unshift(item);
				} else {
					state.items[item.index] = item;
				}
				state.totalCost += item.extraCost;
				item.extraCost = null;
			},
			prepare: (item) => {
				if (item.extraCost != null) {
					const newCost = item.cost + item.extraCost;
					if (newCost > 0) {
						return { payload: {...item, ...{ cost: newCost }} }
					}
					item.extraCost = 1 - item.cost;
					return { payload: {...item, ...{ cost: 1 }} }
				}
				return { payload: item }
			}
		},
		updateLotCostByIndex: (state, action) => {
			const updateData = action.payload;
			// find real index if in search
			if (state.searchTerm.length >= 3 && updateData.id != undefined) {
				const index = state.items.findIndex((row) => row.id === updateData.id);
				updateData.index = index;
			}
			const item = state.items[updateData.index];
			item.cost += updateData.cost;
			if (item.cost > state.items[0].cost) {
				state.items.splice(updateData.index, 1);
				state.items.unshift(item);
			}
			state.totalCost += updateData.cost;
		},
		deleteLot: (state, action) => {
			const item = action.payload;
			// find real index if in search
			if (state.searchTerm.length >= 3) {
				const index = state.items.findIndex((row) => row.id === item.id);
				item.index = index;
			}
			const removedItem = state.items.splice(item.index, 1)[0];
			state.totalCost -= removedItem.cost;
		},
		setSearchTerm: (state, action) => {
			state.searchTerm = action.payload;
		},
		saveItemsToFile: (state, action) => {
			try {
				let tempData = Object.values(state.items).map((row) => ({
					lotName: row.lotName,
					cost: row.cost,
				}));
				const parser = new Parser();
				const csv = parser.parse(tempData);
				const blob = new Blob([csv], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `auc_${new Date().toISOString()}.csv`;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			} catch (err) {
				console.error(err);
			}
		},
		setItems: (state, action) => {
			state.items = action.payload;
		},
		clearLots: (state) => {
			id = 1;
			state.items = [];
			state.totalCost = 0;
			localStorage.setItem("id", id);
		},
		resetLotCosts: (state) => {
			state.items = state.items.map(item => ({...item, ...{ cost: 1 }}));
			state.totalCost = state.items.length;
		}
	}
});

export const { addLot, addLots, updateLot, deleteLot, setSearchTerm, saveItemsToFile, setItems, clearLots, resetLotCosts, updateLotCostByIndex } = auctionDataSlice.actions;

export default auctionDataSlice.reducer;

export const auctionDataNamesSelector = state => [...state.auctionData.items].map(row => row.lotName)
