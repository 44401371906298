import { useEffect, useRef, useState } from "react";
import { WheelSettings } from "../wheel-settings/WheelSettings";
import { NewWheel } from "./NewWheel";
import { WheelGrid } from "./WheelGrid";
import { useSelector } from "react-redux";
import wheelThemes from "./wheelThemes";
import useSound from "use-sound";
import { WinnerDialog } from "./dialog/WinnerDialog";
import { DropoutDialog } from "./dialog/DropoutDialog";


const getWinnerDialogCondition = (showWinningSector, wheelSpinMode, wheelData) => {
	return showWinningSector && wheelSpinMode == 1 || showWinningSector && wheelSpinMode == 2 && wheelData.length == 1;
}

export const WheelViewTab = () => {
	const { wheelData } = useSelector((state) => state.wheelData);
	const {
		wheelDuration,
		wheelSpinMode,
		wheelTheme,
		showChances,
		wheelSize,
		wheelSound,
		wheelSoundVolume,
		emote,
	} = useSelector((state) => state.wheelSettings);

	const wheelRef = useRef();
	const [showWinningSector, setShowWinningSector] = useState(false);
	const [winningSector, setWinningSector] = useState();
	const [isSpinning, setIsSpinning] = useState();
	const [currentIndexState, setCurrentIndexState] = useState();
	const [play, { stop, sound }] = useSound(
		`${process.env.PUBLIC_URL}/sounds/soundThemes.mp3`,
		{
			volume: 0.2,
			sprite: {
				meme: [0, 59000],
				tick: [60900, 1200],
				kekwRemix: [71300, 212000],
			},
		}
	);

	const handleIsSpinning = (isSpinning) => {
		setIsSpinning(isSpinning);
	};

	const handleWheelWinningSector = (winningSector) => {
		setWinningSector(winningSector);
		setShowWinningSector(true);
	};

	useEffect(() => {
		if (isSpinning) {
			if (wheelSound.isBackground) {
				play({ id: wheelSound.soundId });
				sound.loop(true);
				sound.fade(wheelSoundVolume, 0, wheelDuration * 1000);
			}
		}
        if (!isSpinning) {
            stop();
        }
	}, [isSpinning]);

	useEffect(() => {
		if (isSpinning) {
			if (!wheelSound.isBackground) {
				play({ id: wheelSound.soundId });
				if (sound) {
					sound.loop(false);
					sound.volume(wheelSoundVolume);
				}
			}
		}
	}, [currentIndexState]);

	useEffect(() => {
		if (wheelSoundVolume != undefined) {
			if (sound) {
				sound.volume(wheelSoundVolume);
			}
		}
	}, [wheelSoundVolume]);

	return (
		<>
			<WheelGrid>
				<NewWheel
					sectors={wheelData}
					duration={wheelDuration}
					onWin={handleWheelWinningSector}
					ref={wheelRef}
					onSpin={handleIsSpinning}
					wheelSize={wheelSize}
					wheelProps={wheelThemes[wheelTheme]}
					emote={emote}
					onCurrentIndex={setCurrentIndexState}
					showChances={showChances}
				/>
				<WheelSettings wheelRef={wheelRef} isSpinning={isSpinning} />
			</WheelGrid>
			<WinnerDialog winningSector={winningSector} show={getWinnerDialogCondition(showWinningSector, wheelSpinMode, wheelData)} onClose={() => setShowWinningSector(false)} />
			<DropoutDialog droppedOutSector={winningSector} show={showWinningSector && wheelSpinMode == 2 && wheelData.length > 1} onClose={() => setShowWinningSector(false)}/>
		</>
	);
};
