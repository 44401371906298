import { Button } from "grommet"
import { Twitch } from "../../icons/Twitch"
import { useLazyTwitchSignInQuery } from "../../api/authApi"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { setIsAuthenticating } from "../../slices/authSlice"

export const TwitchAuth = () => {

    const { t, i18n } = useTranslation('common');
    const [twitchSignIn] = useLazyTwitchSignInQuery()
    const dispatch = useDispatch();

    const handleTwitchSignIn = () => {
        dispatch(setIsAuthenticating(true));
        twitchSignIn()
        .unwrap()
        .then((response) => window.location.replace(response.redirectUrl))
        .catch(error => dispatch(setIsAuthenticating(false)))
    }

    return (
        <Button primary onClick={handleTwitchSignIn} label={t('signIn')} icon={<Twitch/>}/>
    )
}