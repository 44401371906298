import { Button, Card, CardBody, CardFooter, Layer, Text } from "grommet"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { setNotLocked } from "../../../slices/wheelDataSlice"
import { setSpinStarted, setSupergameFinished, setSupergameStarted } from "../../../slices/wheelSettingsSlice"

export const WheelResetDialog = ({ show, onClose }) => {

    const { t } = useTranslation(['lots-table', 'wheel'], { useSuspense: false })
    const dispatch = useDispatch();

    const handleResetButtonDialog = () => {
        dispatch(setNotLocked(true));
        dispatch(setSpinStarted(false));
        dispatch(setSupergameStarted(false));
        dispatch(setSupergameFinished(false));
        onClose();
    }

    if (show) {
        return (
            <Layer 
                onEsc={onClose}
                onClickOutside={onClose}
            >
                <Card  height="small" width="medium" background="#222222">
                <CardBody pad="medium" align="center" justify="center">
                    <Text size="medium">{t('wheel:resetDialogText')}</Text>
                </CardBody>
                <CardFooter pad="medium">
                    <Button label={t('wheel:resetDialogCancel')} onClick={onClose}/>
                    <Button label={t('wheel:resetButton')} primary onClick={handleResetButtonDialog}/>
                </CardFooter>
                </Card>
            </Layer>
        )
    }
}