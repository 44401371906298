import { setNotLocked, setWheelData } from "../slices/wheelDataSlice";
import { setSupergameFinished } from "../slices/wheelSettingsSlice";
import { calculateItems, calculateWheelItems } from "./utils/storeUtils";

export const calculateWheelDataMiddleware = ({ dispatch, getState }) => next => async action => {
    const result = next(action);
    const supergameStarted = getState().wheelSettings.supergameStarted;
    if (['wheelData/setWheelMode'].includes(action.type)) {
      const calculatedItems = calculateWheelItems(getState);
      return next(setWheelData(calculatedItems));
    }
    if (['wheelData/removeLot'].includes(action.type)) {
      const calculatedItems = calculateWheelItems(getState);
      if (calculatedItems.length == 1 && supergameStarted) {
        dispatch(setSupergameFinished(true));
      }
      dispatch(setNotLocked(true));
      dispatch(setWheelData(calculatedItems));
      return next(setNotLocked(false));
    }
    if ('wheelSettings/setSpinStarted' === action.type && !action.payload) {
      const calculatedItems = calculateItems(getState);
      return next(setWheelData(calculatedItems));
    }
    if (['wheelSettings/setSupergameEnabled', 'wheelSettings/setSupergameCostThreshold'].includes(action.type)) {
      const calculatedItems = calculateItems(getState);
      return next(setWheelData(calculatedItems));
    }
    if (['wheelSettings/setSupergameStarted'].includes(action.type) && !action.payload) {
      const calculatedItems = calculateItems(getState);
      return next(setWheelData(calculatedItems));
    }
    if (['wheelSettings/setSupergameStarted'].includes(action.type) && action.payload) {
      const calculatedItems = calculateItems(getState);
      dispatch(setNotLocked(true));
      dispatch(setWheelData(calculatedItems));
      return next(setNotLocked(false));
    }
    return result;
  };