import { TableCell, TableFooter, TableRow, Text } from "grommet";

export const AuctionTableFooter = ({ count, totalCost }) => {

    return (
        <TableFooter>
            <TableRow>
                <TableCell size="xsmall"><Text>{count}</Text></TableCell>
                <TableCell size="xsmall"></TableCell>
                <TableCell size="large"></TableCell>
                <TableCell size="xsmall"></TableCell>
                <TableCell size="medium"><Text>{totalCost} ₴</Text></TableCell>
                <TableCell size="small"></TableCell>
            </TableRow>
        </TableFooter>
    )
}