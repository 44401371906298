import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_BASE_URL, credentials: 'include' }),
    endpoints: (builder) => ({
        twitchSignIn: builder.query({
            query: () => `/oauth2/authorization/twitch`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
        }),
        twitchCodeAuth: builder.query({
            query: (args) => {
                const { code, scope, state } = args;
                return {
                    url: `/login/oauth2/code/twitch`,
                     params: { code, scope, state }
                }
            }
        })
    }),
})

export const { useLazyTwitchSignInQuery, useLazyTwitchCodeAuthQuery } = authApi