import { Button, Card, CardBody, CardFooter, Image, Layer, Text } from "grommet";
import { randomInt } from "mathjs";
import Confetti from "react-confetti";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { removeLot } from "../../../slices/wheelDataSlice";
import { setSpinStarted, setSupergameStarted } from "../../../slices/wheelSettingsSlice";

const winnersMemes = [
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbGllODc2bDR1aWptNTBydzZ1NHBjOG5jaTJsN3Qxb2FiZGRzdzBvOCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/XGzGnyuUA05uHK0U9W/giphy.gif',
    'https://media.giphy.com/media/W4XD3p911EoG4/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExc3c0YTYybHByY3M4ZXpobW5qd3R3eG9hb2JjZW40cmR0dGVjcWR3MSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/3oKIPwZVHEiM2B3ljq/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcnk0a2sydnd4bjg0eWEwdWlndWZyeWRveXltajQ0c2IwMTI4ZWdybSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/gd0Dqg6rYhttBVCZqd/giphy.gif',
    'https://media.giphy.com/media/1GTZA4flUzQI0/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExM2dtNmNhNTRna2lhNWg2bmVyZWxrZ2ZudjE2aWYwOWJqc2Y3ZnQ4NyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/xEXrQdtLXrAdmyeFA7/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExYmh0N25hdDA5eHlidTkwaWZwc2wxcG92anZ6OTl0a2lwMzl6am05MCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/hU5pTGzUGP6WUlHhBe/giphy.gif',
    'https://media.giphy.com/media/LML5ldpTKLPelFtBfY/giphy.gif',
    'https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExcHNwMGowZHczbTc5czM5ZXJnbmJnOGhwYnlnMTc4N2ZqNnhmanFqOCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/vTqhQldEfAY6c/giphy.gif'
]

export const WinnerDialog = ({ winningSector, show, onClose }) => {

    const { t } = useTranslation(['lots-table', 'wheel'], { useSuspense: false });

    const dispatch = useDispatch();

    const { supergameEnabled,
        supergameStarted,
        supergameFinished, 
        wheelSpinMode } = useSelector(state => state.wheelSettings);

    const handleWinningSectorRemove = () => {
        dispatch(removeLot(winningSector));
        dispatch(setSpinStarted(true));
        onClose();
    }

    const handleSkip = () => {
        onClose();
    }

    const handleOk = () => {
        onClose();
    }

    const handleSupergameStart = () => {
        dispatch(setSupergameStarted(true));
        onClose();
    }

    if (show) {
        return (
            <Confetti tweenDuration={1} numberOfPieces={1000}>
                <Layer 
                  onEsc={onClose}
                  onClickOutside={onClose}
                >
                    <Card height={{min: 'small'}} width={{min: 'medium'}} background="#222222">
                      <CardBody pad="medium" align="center" justify="center">
                          <Image fit="cover" src={winnersMemes[randomInt(0, winnersMemes.length)]} />
                          <Text margin={{top: 'medium'}} size="medium">🎉 🎉 🎉 <Text weight={'bold'}>{winningSector.lotName}</Text> 🎉 🎉 🎉</Text>
                          <Text style={{display: supergameEnabled && !supergameStarted ? 'block' : 'none'}} textAlign="center" size="medium">
                            {t('wheel:supergameDialogText')}
                          </Text>
                      </CardBody>
                      <CardFooter pad={{ top: 'none', bottom: 'medium', left: 'medium', right: 'medium' }} style={{display: wheelSpinMode === 2 ? 'none' : 'flex'}}>
                          <Button label={t('wheel:lotWinRemoveButton')} onClick={handleWinningSectorRemove}/>
                          <Button label={t('wheel:lotWinSkipButton')} primary onClick={handleSkip}/>
                      </CardFooter>
                      <CardFooter pad="medium" style={{display: wheelSpinMode === 2 && (!supergameEnabled || supergameFinished) ? 'flex' : 'none'}}>
                          <Button label="Ok" fill primary onClick={handleOk}/>
                      </CardFooter>
                      <CardFooter pad="medium" style={{display: wheelSpinMode === 2 && supergameEnabled && !supergameFinished ? 'flex' : 'none'}}>
                          <Button label={t('wheel:startSupergameButton')} fill primary onClick={handleSupergameStart}/>
                      </CardFooter>
                    </Card>
                </Layer>
              </Confetti>
        )
    }
    return null;
}