import { DonateCard } from "./DonateCard";
import { useDraggable } from "@dnd-kit/core";

export const DraggableDonateCard = ({ item, index }) => {
    
	const { isDragging, attributes, listeners, setNodeRef } = useDraggable({
		id: `donate-${item.id}`,
		data: {
			index: index,
		},
	});

	return (
		<DonateCard
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			item={item}
			style={{ opacity: isDragging ? 0.5 : null }}
		/>
	);
};
