import { Spinner, Tab, Tabs } from "grommet"
import { WheelDesignSettings } from "./WheelDesignSettings"
import { WidgetSettings } from "./WidgetSettings"
import { WheelControls } from "./WheelControls"
import { useTranslation } from "react-i18next"

export const RouletteSettings = ({rouletteWidgetSettings, wheelDesignValuesHandler, wheelRef, isSpinning, handleWheelSound}) => {

    const { t, ready } = useTranslation('roulette', {useSuspense: false});

    if (!ready) {
        return (
            <Spinner/>
        )
    }
    if (ready) {
        return (
            <Tabs alignControls="start">
                <Tab title={t('controlsTabTitle')}>
                    <WheelControls 
                        wheelRef={wheelRef} 
                        rouletteWidgetSettings={rouletteWidgetSettings} 
                        isSpinning={isSpinning}
                        onWheelSoundVolumeChange={handleWheelSound}
                    />
                </Tab>
                <Tab title={t('wheelDesignTabTitle')}>
                    <WheelDesignSettings rouletteWidgetSettings={rouletteWidgetSettings} formValuesHandler={wheelDesignValuesHandler}/>
                </Tab>
                <Tab title={t('widgetSettingsTabTitle')}>
                    <WidgetSettings rouletteWidgetSettings={rouletteWidgetSettings}/>
                </Tab>
            </Tabs>
        )
    }
}