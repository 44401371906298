import { Box, Main } from "grommet"
import { AppHeader } from "../components/layout/AppHeader"
import { Outlet } from "react-router-dom"
import { AppFooter } from "../components/layout/AppFooter"

export const HomePage = () => {

    return (
        <Box background={{color: '#1c1c1c'}} height={{ min: '100%' }}>
          <AppHeader></AppHeader>
          <Main>
            <Outlet/>
          </Main>
          <AppFooter></AppFooter>
        </Box>
    )
}