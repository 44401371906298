import { Box, Button, Form, FormField, RangeInput, Select, Spinner, TextInput } from "grommet"
import { useEffect, useRef, useState } from "react"
import { useUpdateRouletteWidgetMutation } from "../../api/rouletteWidgetApi";
import { useTranslation } from "react-i18next";

export const WheelDesignSettings = ({rouletteWidgetSettings, formValuesHandler}) => {

    const { t, i18n, ready } = useTranslation(['roulette', 'wheel'], { useSuspense: false });

    const [wheelThemes, setWheelThemes] = useState([
        { name: { en: t('wheel:mainTheme', { lng: 'en' }), uk: t('wheel:mainTheme', { lng: 'uk' }) }, image: `wheel-theme-1.svg`, radius: 0.99 },
        { name: { en: t('wheel:christmasTheme', { lng: 'en' }), uk: t('wheel:christmasTheme', { lng: 'uk' }) }, image: `wheel-theme-2.svg`, radius: 0.9 },
        { name: { en: t('wheel:noneTheme', { lng: 'en' }), uk: t('wheel:noneTheme', { lng: 'uk' })}, image: `wheel-theme-3.svg`, radius: 0.99 }
    ]);

    const [formValues, setFormValues] = useState({...rouletteWidgetSettings, ...{ wheelTheme: wheelThemes.filter(theme => theme.image === rouletteWidgetSettings.wheelTheme)[0] }});

    const [updateRouletteWidget, {isLoading: isRouletteWidgetUpdating}] = useUpdateRouletteWidgetMutation();

    const wheelChanged = useRef(false);

    useEffect(() => {
        if (wheelChanged.current) {
            formValuesHandler(formValues);
            wheelChanged.current = false;
        }
    }, [formValues])

    const handleFormSubmit = (values, touched) => {
        let updatedFieldNames = Object.entries(touched)
            .filter((item) => item[1])
            .map(item => item[0])
        const newValues = updatedFieldNames.map(fieldName => ({[fieldName]: values[fieldName]}))
        const valuesToUpdate = Object.assign({}, ...newValues )
        if (valuesToUpdate.wheelTheme !== undefined) {
            valuesToUpdate.wheelTheme = valuesToUpdate.wheelTheme.image
        }
        valuesToUpdate.id = rouletteWidgetSettings.id;
        updateRouletteWidget(valuesToUpdate);
    }

    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        return (
            <Form
              validate="blur"
              messages={{
                required: 'This is a required field.',
              }}
              value={formValues}
              onChange={(values) => {
                    setFormValues(values);
                    wheelChanged.current = true;
                }}
              onSubmit={({value, touched}) => handleFormSubmit(value, touched)}
            >
                <FormField label={t('wheelTheme')}
                    htmlFor="wheelTheme"
                    name="wheelTheme">
                    <Select
                        id="wheelTheme" 
                        name="wheelTheme" 
                        options={wheelThemes}
                        labelKey={(option) => option.name[i18n.language]}
                        valueKey={value => value.image}
                    />
                </FormField>
                <FormField label={t('borderWidth')}
                    htmlFor="borderWidth"
                    name="borderWidth" noBorder>
                    <RangeInput id="borderWidth" name="borderWidth" min={0} max={25} step={0.1}/>
                </FormField>
                <FormField label={t('borderColor')}
                    htmlFor="borderColor"
                    name="borderColor">
                    <TextInput id="borderColor" name="borderColor" type="color" style={{padding: 0}} />
                </FormField>
                <FormField label={t('font')}
                    htmlFor="font"
                    name="font">
                    <Select id="font" name="font" options={['Roboto Mono', 'Caveat', 'Montserrat Alternates', 'Neucha', 'Pacifico', 'Comfortaa', 'Lobster']}/>
                </FormField>
                <FormField label={t('fontSize')}
                    htmlFor="fontSize"
                    name="fontSize" noBorder>
                    <RangeInput id="fontSize" name="fontSize" min={10} max={100} step={1}/>
                </FormField>
                <FormField label={t('labelRadius')}
                    htmlFor="labelRadius"
                    name="labelRadius" noBorder>
                    <RangeInput id="labelRadius" name="labelRadius" type="number" min={0} max={1} step={0.01} />
                </FormField>
                <FormField label={t('labelRotation')}
                    htmlFor="labelRotation"
                    name="labelRotation" noBorder>
                    <RangeInput id="labelRotation" name="labelRotation" min={0} max={359} step={1}/>
                </FormField>
                <FormField label={t('labelStrokeWidth')}
                    htmlFor="labelStrokeWidth"
                    name="labelStrokeWidth" noBorder>
                    <RangeInput id="labelStrokeWidth" name="labelStrokeWidth" min={0} max={30} step={1} />
                </FormField>
                <FormField label={t('labelStrokeColor')}
                    htmlFor="labelStrokeColor"
                    name="labelStrokeColor">
                    <TextInput id="labelStrokeColor" name="labelStrokeColor" type="color" style={{padding: 0}} />
                </FormField>
                <FormField label={t('lineWidth')}
                    htmlFor="lineWidth"
                    name="lineWidth" noBorder>
                    <RangeInput id="lineWidth" name="lineWidth" min={0} max={20} step={0.1}/>
                </FormField>
                <FormField label={t('lineColor')}
                    htmlFor="lineColor"
                    name="lineColor">
                    <TextInput id="lineColor" name="lineColor" type="color" style={{padding: 0}} />
                </FormField>
                <Box margin={{top: 'small'}}>
                    <Button label={t('saveButton')} type="submit" primary busy={isRouletteWidgetUpdating}/>
                </Box>
            </Form>
        )
    }
}