import { Box, Button, CheckBox, Form, FormField, Spinner, Text, TextInput } from "grommet"
import { Checkmark, Copy } from "grommet-icons"
import { useState } from "react"
import { useUpdateRouletteWidgetMutation } from "../../api/rouletteWidgetApi";
import { useTranslation } from "react-i18next";

export const WidgetSettings = ({rouletteWidgetSettings}) => {

    const { t, ready } = useTranslation('roulette', { useSuspense: false });
    const [updateRouletteWidget, {isLoading: isRouletteWidgetUpdating}] = useUpdateRouletteWidgetMutation();
    const [activationThreshold, setActivationThreshold] = useState(rouletteWidgetSettings.activationThreshold);
    const [urlCopied, setUrlCopied] = useState(false);
    const [widgetUrl, setWidgetUrl] = useState(`${process.env.REACT_APP_FE_URL}/widgets/roulette/${rouletteWidgetSettings.widgetToken}`);
    const [useGoal, setUseGoal] = useState(rouletteWidgetSettings.withGoal);
    const [goalName, setGoalName] = useState(rouletteWidgetSettings.goalName);

    const handleUrlCopy = () => {
        navigator.clipboard.writeText(widgetUrl)
        setUrlCopied(true);
        setTimeout(() => {
            setUrlCopied(false);
        }, 1000)
    }
    const handleSaveButton = () => {
        const body = {
            id: rouletteWidgetSettings.id,
            activationThreshold: activationThreshold,
            withGoal: useGoal,
            goalName: goalName
        }
        updateRouletteWidget(body);
    }

    if (!ready) {
        return (<Spinner/>)
    }
    if (ready) {
        return (
            <Box>
                <Form>
                    <Text weight={500} size="14px">{t('widgetLink')}</Text>
                    <Box direction="row" border round="xsmall" margin={{top: 'xsmall', bottom: 'xsmall'}}>
                        <TextInput plain type="text" value={widgetUrl}/>
                        <Button icon={urlCopied ? <Checkmark/> : <Copy/>} onClick={handleUrlCopy}/>
                    </Box>
                    <FormField label={t('activationThreshold')}>
                        <TextInput type="number" value={activationThreshold} onChange={(event) => setActivationThreshold(+event.target.value)}/>
                    </FormField>
                    {/* <FormField label="Activation mode" noBorder>
                        <CheckBox toggle label="On - cummulative, Off - fixed"/>
                    </FormField>
                    <Text weight={500} size="14px">Cummulative status widget</Text>
                    <Box direction="row" border round="xsmall" margin={{top: 'xsmall', bottom: 'xsmall'}}>
                        <TextInput plain type="text" value="https://widget.whatyouwish.pro"/>
                        <Button icon={<Copy/>}/>
                    </Box> */}
                    <FormField label={t('useGoal')} noBorder info={t('useGoalInfo')}>
                        <CheckBox toggle label={t('useGoalCheckbox')} checked={useGoal} onChange={(event) => setUseGoal(event.target.checked)}/>
                    </FormField>
                    {useGoal && (
                        <FormField label={t('goalName')}>
                            <TextInput type="text" value={goalName} onChange={(event) => setGoalName(event.target.value)}/>
                        </FormField>
                    )}
                    <Box margin={{top: 'small'}}>
                        <Button label={t('saveButton')} type="submit" primary busy={isRouletteWidgetUpdating} onClick={() => handleSaveButton()}/>
                    </Box>
                </Form>
            </Box>
        )
    }
}