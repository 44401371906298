import { Box, Button, Heading, Image, Markdown, Text } from "grommet"
import { useTranslation } from "react-i18next"

export const DonatikSettingsLayout = ({apiKeyInput, donateUrlInput}) => {
    const { t } = useTranslation('donatik-settings');

    return (
        <Box>
            <Box direction="row" gap="small" align="center" margin={{bottom: 'small'}}>
                <Image height={'25px'} width={'115px'} src={`${process.env.PUBLIC_URL}/images/donatik-logo.svg`} /><Heading margin={{top: 'none', bottom: 'none'}} level={2} size="medium" weight={'bold'}>{t('title')}</Heading>
            </Box>
            <Box margin={{bottom: 'small'}}>
                <Box direction="row" gap="xsmall">
                    <Text>{t('step1')}</Text>
                    <Button href="https://admin.donatik.io/settings/api" target="_blank" style={{textDecoration: 'underline'}}>
                        {t('step1-link')}
                    </Button>
                </Box>
                <Markdown>
                    {t('step2')}
                </Markdown>
                <Markdown>
                    {t('step3')}
                </Markdown>
                <Markdown>
                    {t('step4')}
                </Markdown>
            </Box>
            {apiKeyInput}
            <Box margin={{top: 'small', bottom: 'small'}}>
                <Markdown>
                    {t('step5')}
                </Markdown>
            </Box>
            {donateUrlInput}
        </Box>
    )
}