import { Box, Button, Card, CardBody, CardFooter, CardHeader, Heading, Layer, Tab, Tabs, Text } from "grommet"

const newFeaturesEn = [
    'Support of donatik.io',
    'Site features description in About tab.',
    'New site theme.'
];

const improvementsEn = [
    'Performance of the lots table. Fast adding over 5000 lots.',
    'Performance of drag & drop animation.',
    'Performance of the wheel animation.',
    'UX of timer and donates list section.',
    'UX of the wheel control panel.'
];

const bugFixesEn = [
    'The translation issue, when some dropdowns were without the translation.',
    'Donation message long text issue.'
];

const newFeaturesUa = [
    'Підтримка donatik.io',
    'Опис функцій сайту у в кладці Як працює.',
    'Нова тема сайту.'
];

const improvementsUa = [
    'Оптимізовано таблицю лотів. Швидке додавання більше 5000 лотів.',
    'Drag & drop анімація.',
    'Оптимізовано анімацію колеса.',
    'Інтерфейс секції таймеру та списку донатів.',
    'Інтерфейс панелі керування колесом.'
];

const bugFixesUa = [
    'Проблема перекладу, коли випадаючі списки були без переклада.',
    'Проблема довгого тексту в повідомленні пожертвування.'
];

export const Changelog = ({ show, setShow }) => {

    return (
        show && (
            <Layer>
                <Card background="#222222">
                    <CardHeader pad={{top: 'medium', left: 'medium', right: 'medium', bottom: 'none'}} justify="center">
                        <Heading level={1} weight={'bold'}>New Update v0.0.5</Heading>
                    </CardHeader>
                    <CardBody pad={{top: 'none', left: 'medium', right: 'medium', bottom: 'medium'}} align="start" justify="center">
                        <Tabs alignControls="center" flex="grow">
                            <Tab title="🇬🇧 EN">
                                <Heading level={2} weight={'bold'}>Added:</Heading>
                                {newFeaturesEn.map((value, index) => <Box><Text>- {value}</Text></Box>)}
                                <Heading level={2} weight={'bold'}>Improved:</Heading>
                                {improvementsEn.map((value, index) => <Box><Text>- {value}</Text></Box>)}
                                <Heading level={2} weight={'bold'}>Fixed:</Heading>
                                {bugFixesEn.map((value, index) => <Box><Text>- {value}</Text></Box>)}
                            </Tab>
                            <Tab title="🇺🇦 UA">
                                <Heading level={2} weight={'bold'}>Додано:</Heading>
                                {newFeaturesUa.map((value, index) => <Box><Text>- {value}</Text></Box>)}
                                <Heading level={2} weight={'bold'}>Покращено:</Heading>
                                {improvementsUa.map((value, index) => <Box><Text>- {value}</Text></Box>)}
                                <Heading level={2} weight={'bold'}>Виправлено:</Heading>
                                {bugFixesUa.map((value, index) => <Box><Text>- {value}</Text></Box>)}
                            </Tab>
                        </Tabs>
                    </CardBody>
                    <CardFooter pad="medium">
                        <Button primary fill label={'Ok'} onClick={setShow}/>
                    </CardFooter>
                </Card>
            </Layer>
        )
    )
}