import { useDroppable } from "@dnd-kit/core";
import { AuctionTableRow } from "./AuctionTableRow";

export const DroppableAuctionTableRow = ({ dataRow, index }) => {

    const {isOver, setNodeRef} = useDroppable({
        id: `row-${dataRow.id}`,
        data: {
            id: dataRow.id,
            index
        }
    });

    return (
        <AuctionTableRow 
            ref={setNodeRef}
            key={dataRow.id}
            dataRow={dataRow}
            index={index}
            style={{background: isOver ? '#333333' : ''}}
        />
    )
}