import { TableCell, TableHeader, TableRow } from "grommet";
import { memo } from "react";

export const AuctionTableHeader = memo(({ columns }) => (
    <TableHeader>
        <TableRow>
            {columns.map(row => (
                <TableCell key={row.id} size={row.size} scope="col" border="bottom"><strong>{row.header}</strong></TableCell>
            ))}
        </TableRow>
    </TableHeader>
))