import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const donatesApi = createApi({
    reducerPath: 'donatesApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/donates`, credentials: 'include' }),
    tagTypes: ['DonatikDonate'],
    endpoints: (builder) => ({
        getDonatelloApiKey: builder.query({
            query: () => `/api-key`,
            transformResponse: (response) => response.apiKey,
            transformErrorResponse: (response) => response.status,
        }),
        generateDonatelloApiKey: builder.mutation({
            query: () => ({url: `/api-key`, method: 'POST'}),
            transformResponse: (response) => response.apiKey,
            transformErrorResponse: (response) => response.status,
        }),
        getDonatesUrl: builder.query({
            query: () => `/url`,
            transformResponse: (response) => response.callbackUrl,
            transformErrorResponse: (response) => response.status,
        }),
        getDonatikApiKey: builder.query({
            query: () => `/api-key/donatik`,
            transformResponse: (response) => response.apiKey,
            transformErrorResponse: (response) => response.status,
            providesTags: ['DonatikDonate']
        }),
        setDonatikApiKey: builder.mutation({
            query: (args) => {
                const { apiKey } = args;
                return { url: `/api-key/donatik`, method: 'POST', params: { apiKey } };
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['DonatikDonate']
        }),
        getDonatikDonatesUrl: builder.query({
            query: () => `/url/donatik`,
            transformResponse: (response) => response.callbackUrl,
            transformErrorResponse: (response) => response.status,
            providesTags: ['DonatikDonate']
        }),
    }),
})

export const { useGetDonatelloApiKeyQuery, useGenerateDonatelloApiKeyMutation, useGetDonatesUrlQuery, useSetDonatikApiKeyMutation, useGetDonatikDonatesUrlQuery, useGetDonatikApiKeyQuery } = donatesApi