import { Box, Button, Card, CardBody, Heading } from "grommet"
import { RouletteItems } from "./RouletteItems"
import { Add } from "grommet-icons"
import { useState } from "react"
import { RouletteItemForm } from "./RouletteItemForm"
import { RouletteGrid } from "./RouletteGrid"
import { useDispatch } from "react-redux"
import { resetCategoryPreview, setCategoryPreview } from "../../slices/rouletteSlice"
import { useTranslation } from "react-i18next"

const getDefaultDisplayForm = (items) => {
    return Object.assign({}, ...items.map(item => {
        return {[item.id]: false}
    }))
}

export const RouletteItemsCard = ({widget}) => {
    const { t, ready } = useTranslation('roulette', { useSuspense: false });
    const dispatch = useDispatch();

    const [isCreateFormDisplayed, setCreateFormDisaplyed] = useState(false);
    const [isEditFormDisplayed, setEditFormDisaplyed] = useState(false);
    const [displayEditForm, setDisplayEditForm] = useState(getDefaultDisplayForm(widget.categoryEntries));
    const [editCategory, setEditCategory] = useState();

    const handleFormClose = () => {
        setCreateFormDisaplyed(false);
        setEditFormDisaplyed(false);
        setDisplayEditForm(getDefaultDisplayForm(widget.categoryEntries));
        dispatch(resetCategoryPreview());
    }

    const handleDisplayEditForm = (value) => {
        setDisplayEditForm(value)
    }

    const handleEditItem = (item, isEditFormDisplayed) => {
        setEditFormDisaplyed(isEditFormDisplayed);
        setEditCategory(item);
    }

    const handlePreviewItem = (previewItem) => {
        dispatch(setCategoryPreview(previewItem));
    }

    if (!(isCreateFormDisplayed || isEditFormDisplayed)) {
        return (
            <Card /*style={{display: hidden ? 'none' : 'flex'}}*/ background="#222222" fill="vertical" margin={{top: 'medium'}}>
                <CardBody pad="medium">
                    <Box direction="row" justify="between" align="center" margin={{bottom: 'medium'}}>
                        <Heading level={3} weight={'bold'} margin="none">{t('rouletteRewards')}</Heading>
                        <Box direction="row" gap="small">
                            <Button primary label={t('createRewardButton')} icon={<Add/>} onClick={() => setCreateFormDisaplyed(true)}/>
                            {/* <CheckBox toggle label="Custom rarity"/> */}
                        </Box>
                    </Box>
                    <RouletteItems 
                        items={widget.categoryEntries} 
                        onItemEdit={({item, isEditFormDisplayed}) => handleEditItem(item, isEditFormDisplayed)}
                        displayEditForm={displayEditForm}
                        setDisplayEditForm={handleDisplayEditForm}
                    />
                </CardBody>
            </Card>
        )
    }
    if (isCreateFormDisplayed || isEditFormDisplayed) {
        return (
            <RouletteGrid
                margin={{top: 'medium'}}
                leftCard={
                    <Box>
                        <Box direction="row" justify="between" align="center" margin={{bottom: 'medium'}}>
                            <Heading level={3} weight={'bold'} margin="none">{t('rouletteRewards')}</Heading>
                            {/* <Box direction="row" gap="small">
                                <CheckBox toggle label="Custom rarity"/>
                            </Box> */}
                        </Box>
                        <RouletteItems 
                            items={widget.categoryEntries} 
                            onItemEdit={({item, isEditFormDisplayed}) => handleEditItem(item, isEditFormDisplayed)}
                            displayEditForm={displayEditForm}
                            setDisplayEditForm={handleDisplayEditForm}
                        />
                    </Box>
                }
                rightCard={<RouletteItemForm
                                userId={widget.ownerId}
                                onClose={handleFormClose}
                                isEditForm={isEditFormDisplayed} 
                                editItem={editCategory}
                                previewItem={handlePreviewItem}
                            />}
            />
        )
    }
}