import { Box, Button, Form, FormField, Heading, Image, Notification, TextInput } from "grommet";
import { Twitch } from "../../icons/Twitch";
import { useTranslation } from "react-i18next";

const costValidation = [
    (cost) => {
        console.log(cost);
        if (cost && +cost <= 0) return 'Cost should be greater than 0';
        return undefined;
    }
]

export const TwitchSettingsLayout = ({rewardValue, onRewardChange, onRewardSubmit, isRewardCreating }) => {

    const { t } = useTranslation('twitch-settings');

    return (
        <Box>
            <Box direction="row" gap="small" align="center" margin={{bottom: 'small'}}>
                <Twitch/>
                <Heading margin={{top: 'none', bottom: 'none'}} level={2} size="medium" weight={'bold'}>
                    {t('title')}
                </Heading>
            </Box>
            <Box direction="row" gap="medium">
                <Box>
                    <Heading level={3} margin={{bottom: 'small', top: 'small'}}>{t('title2')}</Heading>
                    <Box width={'large'}>
                        {rewardValue && (
                            <Form 
                            value={rewardValue}
                            messages={{required: t('requiredField')}}
                            onChange={(value) => onRewardChange(value)}
                            onSubmit={({value}) => onRewardSubmit(value)}
                            >
                                <FormField name="rewardName" label={t('rewardInputLable')} required={{ indicator: false }} info={t('rewardInputHelp')} htmlFor="reward-name-create-form">
                                    <TextInput name="rewardName" id="reward-name-create-form" placeholder={t('rewardInputPlaceholder')}/>
                                </FormField>
                                <FormField name="cost" label={t('rewardInput2')} type="number" validate={costValidation} required={{ indicator: false }} info={t('rewardInputHelp2')} htmlFor="reward-cost-create-form">
                                    <TextInput name="cost" id="reward-cost-create-form" type="number" placeholder={t('rewardInputPlaceholder2')}/>
                                </FormField>
                                <Button primary label={t('rewardSubmitButton')} type="submit" busy={isRewardCreating}/>
                            </Form>
                        )}
                        {!rewardValue && (
                            <Form>
                                <FormField name="rewardName" label={t('rewardInputLable')} info={t('rewardInputHelp')} htmlFor="reward-name-create-form">
                                    <TextInput name="rewardName" id="reward-name-create-form" placeholder={t('rewardInputPlaceholder')}/>
                                </FormField>
                                <FormField name="cost" label={t('rewardInput2')} type="number" info={t('rewardInputHelp2')} tmlFor="reward-cost-create-form">
                                    <TextInput name="cost" id="reward-cost-create-form" type="number" placeholder={t('rewardInputPlaceholder2')}/>
                                </FormField>
                                <Button primary label={t('rewardSubmitButton')} type="submit"/>
                            </Form>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Notification
                        status="warning"
                        message={t('twitchRewardsSettingsDescription')}
                    />
                    <Image margin={{ top: 'medium' }} height={'330px'} width={'495px'} src={`${process.env.PUBLIC_URL}/images/reward-help.png`} />
                </Box>
            </Box>
        </Box>
    )
}