import { Box, Button, Footer, Markdown, RoutedButton, Stack, Text } from 'grommet';
import { Currency } from 'grommet-icons';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';

export const AppFooter = () => {
    const year = new Date().getFullYear();

    const { t } = useTranslation('common');
    return (
        <Footer pad={'medium'} background="background-front" style={{position: 'relative'}}>
            <Text size="xsmall">
                &copy; {year} What You Wish
            </Text>
            <Text style={{position: 'absolute', bottom: 5, left: 5}} color={'#666666'} size='12px'>{process.env.REACT_APP_VERSION}</Text>
            <Box direction='row' align='center' gap='medium'>
                <Button style={{fontSize: '14px', lineHeight: '16px'}} href='https://donatello.to/codehilation' target='_blank'>{<Box direction='row' align='center' gap='small'><Currency/>{t('supportAuthor')}</Box>}</Button>
                <Button style={{fontSize: '14px', lineHeight: '16px'}} href='https://www.twitch.tv/codehilation' target='_blank'>{t('developedBy')}</Button>
            </Box>
        </Footer>
    );
  };