const wheelThemes = {
    classic: {
        name: 'Classic',
        isInteractive: false,
        debug: false,
        radius: 0.99,
        itemLabelRadius: 0.85,
        itemLabelRadiusMax: 0.2,
        itemLabelRotation: 0,
        itemLabelAlign: 'right',
        itemLabelColors: ['#fff'],
        itemLabelStrokeWidth: 1,
        itemLabelStrokeColor: '#000000',
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Roboto Mono',
        itemLabelFontSizeMax: 25,
        lineWidth: 1,
        lineColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 2,
        pointerAngle: 90,
        overlayImage: `${process.env.PUBLIC_URL}/images/wheel-theme-1.svg`
    },
    christmas: {
        name: 'Christmas',
        isInteractive: false,
        debug: false,
        radius: 0.9,
        itemLabelRadius: 0.85,
        itemLabelRadiusMax: 0.2,
        itemLabelRotation: 0,
        itemLabelAlign: 'right',
        itemLabelColors: ['#fff'],
        itemLabelStrokeWidth: 1,
        itemLabelStrokeColor: '#000000',
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Roboto Mono',
        itemLabelFontSizeMax: 25,
        lineWidth: 1,
        lineColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 2,
        pointerAngle: 90,
        overlayImage: `${process.env.PUBLIC_URL}/images/wheel-theme-2.svg`
    },
    minimalistic: {
        name: 'Minimalistic',
        isInteractive: false,
        debug: false,
        radius: 0.99,
        itemLabelRadius: 0.85,
        itemLabelRadiusMax: 0.2,
        itemLabelRotation: 0,
        itemLabelAlign: 'right',
        itemLabelColors: ['#fff'],
        itemLabelStrokeWidth: 1,
        itemLabelStrokeColor: '#000000',
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Roboto Mono',
        itemLabelFontSizeMax: 25,
        lineWidth: 1,
        lineColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 2,
        pointerAngle: 90,
        overlayImage: `${process.env.PUBLIC_URL}/images/wheel-theme-3.svg`
    },
}

export default wheelThemes;