import { Table, Data } from "grommet"
import '../../styles.css'
import { useTranslation } from "react-i18next"
import { AuctionTableToolbar } from "./AuctionTableToolbar"
import { AuctionTableHeader } from "./AuctionTableHeader"
import { AuctionTableBody } from "./AuctionTableBody"
import { AuctionTableFooter } from "./AuctionTableFooter"
import { useMemo } from "react"

export const AuctionTable = ({ items, totalCost, searchTerm }) => {

    const { t } = useTranslation('lots-table');
    
    const columns = [
        {
            id: 1,
            header: '#',
            size: 'xsmall'
        },
        {
            id: 2,
            header: t('lotNumber'),
            size: 'xsmall'
        },
        {
            id: 3,
            header: t('lotName'),
            size: 'large'
        },
        {
            id: 4,
            header: t('lotChance'),
            size: 'xsmall'
        },
        {
            id: 5,
            header: t('lotCost'),
            size: 'medium'
        },
        {
            id: 6,
            header: t('lotActions'),
            size: 'small'
        }
    ];

    const filteredItems = useMemo(() => {
		return items.filter(item => !(searchTerm.length >= 3) || item.lotName.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()));
	}, [items, searchTerm])

    return (
        <Data>
            <AuctionTableToolbar data={filteredItems} />
            <Table>
                <AuctionTableHeader columns={columns}/>
                <AuctionTableBody data={filteredItems}/>
                <AuctionTableFooter count={filteredItems.length} totalCost={totalCost}/>
            </Table>
        </Data>
    )
}