import { Box, Button, Card, CardBody, CardFooter, FileInput, Form, FormField, RangeInput, Select, Spinner, Tab, Tabs, Text, TextInput } from "grommet"
import { Close } from "grommet-icons"
import { useEffect, useState } from "react";
import { useCreateRouletteCategoryMutation, useDeleteRouletteCategoryImageMutation, useDeleteRouletteCategoryMutation, useUpdateRouletteCategoryMutation, useUploadRouletteCategoryImageMutation } from "../../api/rouletteWidgetApi";
import { useLazyGetFileQuery } from "../../api/fileApi";
import { useTranslation } from "react-i18next";

const rarityOptions = [
    {
        chance: 500,
        element: <Box pad="5px"><Text color="#fdfefe">Common</Text></Box>
    },
    {
        chance: 250,
        element: <Box pad="5px"><Text color="#2fde78">Uncommon</Text></Box>
    },
    {
        chance: 160,
        element: <Box pad="5px"><Text color="#385ef5">Rare</Text></Box>
    },
    {
        chance: 70,
        element: <Box pad="5px"><Text color="#a14ac7">Epic</Text></Box>
    },
    {
        chance: 15,
        element: <Box pad="5px"><Text color="#f1c40f">Legendary</Text></Box>
    },
    {
        chance: 7,
        element: <Box pad="5px"><Text color="#D35400">Methyc</Text></Box>
    },
    {
        chance: 1,
        element: <Box pad="5px"><Text color="#c70404">Relic</Text></Box>
    }
]

const categoryValidationRules = [
    {
        regexp: /^.{1,128}$/,
        message: 'Maximum 128 characters',
        status: 'error',
    }
]

const rewardNamesValidationRules = [
    {
        regexp: /^.{1,256}$/,
        message: 'Maximum 256 characters',
        status: 'error',
    }
]

const getRandomRgbColor = () => {
    return Math.floor(Math.random() * 180) + 50;
}

const generateRandomColor = () => {
    let [r, g, b] = [getRandomRgbColor(), getRandomRgbColor(), getRandomRgbColor()];
    let hr = r.toString(16).padStart(2, '0');
    let hg = g.toString(16).padStart(2, '0');
    let hb = b.toString(16).padStart(2, '0');
    return `#${hr}${hg}${hb}`;
}

const defaultValues = {
    categoryName: '',
    rewardNames: '',
    rarity: rarityOptions[0],
    backgroundColor: generateRandomColor(),
    imageOpacity: 1,
    imageRadius: 0.5,
    imageRotation: 0,
    imageScale: 1,
    labelColor: '#ffffff'
}

export const RouletteItemForm = ({userId, onClose, isEditForm, editItem, previewItem}) => {

    const { t, ready } = useTranslation('roulette', { useSuspense: false });
    const [getFile] = useLazyGetFileQuery();
    const [createCategory, {isLoading: isCategoryCreating}] = useCreateRouletteCategoryMutation();
    const [updateCategory, {isLoading: isCategoryUpdating}] = useUpdateRouletteCategoryMutation();
    const [uploadCategoryImage, {isLoading: isCategoryUploading}] = useUploadRouletteCategoryImageMutation();
    const [deleteRouletteCategoryImage, {isLoading: isCategoryDeleting}] = useDeleteRouletteCategoryImageMutation();
    const [deleteCategory] = useDeleteRouletteCategoryMutation();

    const [rarityValue, setRarityValue] = useState(500)
    const [formValues, setFormValues] = useState(defaultValues);

    useEffect(() => {
        if (isEditForm && editItem) {
            setRarityValue(editItem.rarity)
            if (editItem.image !== null) {
                getFile({userId: userId, fileName: editItem.image})
                .then(response => {
                    const data = response?.data?.data;
                    if (data) {
                        const myFile = new File([data], editItem.image, {
                            type: response.data.headers['content-type'],
                            lastModified: new Date(),
                        });
                        setFormValues({...editItem, ...{ file: [myFile] }});
                    } else {
                        setFormValues(editItem);
                    }
                })
            } else {
                setFormValues(editItem)
            }
        }
    }, [editItem])

    useEffect(() => {
        const setPreviewItem = async () => {
            if (formValues && formValues.rewardNames) {
                const previewItemObj = Object.assign({}, formValues);
                if (typeof previewItemObj.rarity === 'object') {
                    previewItemObj.rarity = previewItemObj.rarity.chance;
                }
                if (formValues.file && formValues.file.length == 1) {
                    let image = formValues.file[0];
                    if (image.name !== previewItemObj.image) {
                        let imageBase64 = await toBase64(image);
                        previewItemObj.image = imageBase64;
                    }
                }
                if (!formValues.file || formValues.file.length == 0 && previewItemObj.image) {
                    previewItemObj.image = null;
                }
                previewItem(previewItemObj);
            }
        }
        setPreviewItem();
    }, [formValues])

    const handleCategoryCreateForm = (values) => {
        if (isEditForm) {
            if (typeof values.rarity === 'object') {
                values.rarity = values.rarity.chance;
            }
            const file = values.file !== undefined && values.file.length !== 0 ? values.file[0] : [];
            delete values.file;
            updateCategory({id: values.id, body: values})
                .then(() => file.length !== 0 ? uploadCategoryImage({id: values.id, file: file}) : deleteRouletteCategoryImage({id: values.id}))
                .then(() => onClose(true))
        } else {
            values.rarity = values.rarity.chance;
            const file = values.file !== undefined && values.file.length !== 0 ? values.file[0] : [];
            delete values.file;
            createCategory(values)
                .then((response) => file.length !== 0 ? uploadCategoryImage({id: response.data.id, file: file}) : undefined)
                .then(() => setFormValues(defaultValues))
                .then(() => onClose(true))
        }
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const handleCategoryDelete = (values) => {
        deleteCategory({id: values.id})
            .then(() => onClose(true));
    }

    
    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        return (
            <Form 
                validate="blur"
                messages={{
                    required: t('requiredFieldMessage'),
                }}
                value={formValues}
                onChange={(values) => setFormValues(values)}
                onSubmit={({value}) => handleCategoryCreateForm(value)}>
                <Card background="dark-1">
                    <CardBody direction="row" justify="between" align="start" pad={{top: 'medium', left: 'medium', right: 'medium'}}>
                        <Tabs>
                            <Tab title={t('rewardTabTitle')}>
                                <Box margin={{top: 'small'}}>
                                    <FormField label={t('categoryName')} htmlFor="categoryName" name="categoryName" required={{indicator: true}} validate={categoryValidationRules}>
                                        <TextInput id="categoryName" name="categoryName"/>
                                    </FormField>
                                    <FormField label={t('rewardNames')} htmlFor="rewardNames" name="rewardNames" required={{indicator: true}} info={t('rewardNamesInfo')} validate={rewardNamesValidationRules}>
                                        <TextInput id="rewardNames" name="rewardNames"/>
                                    </FormField>
                                    <FormField label={t('rarity')} htmlFor="rarity" name="rarity">
                                        <Select 
                                            id="rarity"
                                            name="rarity"
                                            options={rarityOptions} 
                                            value={rarityOptions.filter(option => option.chance == rarityValue)[0]}
                                            labelKey={'element'}
                                            valueKey={(option) => option.chance}
                                            onChange={({ option }) => setRarityValue(option)}
                                        />
                                    </FormField>
                                </Box>
                            </Tab>
                            <Tab title={t('designTabTitle')}>
                                <Box margin={{top: 'small'}}>
                                    <FormField label={t('backgroundColor')} htmlFor="backgroundColor" name="backgroundColor">
                                        <TextInput id="backgroundColor" name="backgroundColor" type="color" style={{padding: 0}}/>
                                    </FormField>
                                    <FormField label={t('image')} htmlFor="file" name="file">
                                        <FileInput id="file" name="file" /*onChange={(event, { files }) => handleImageUpload(files[0])}*//>
                                    </FormField>
                                    <FormField label={t('imageOpacity')} htmlFor="imageOpacity" name="imageOpacity" noBorder>
                                        <RangeInput id="imageOpacity" name="imageOpacity" min={0} max={1} step={0.01} />
                                    </FormField>
                                    <FormField label={t('imageRadius')} htmlFor="imageRadius" name="imageRadius" noBorder>
                                        <RangeInput id="imageRadius" name="imageRadius" min={0} max={1} step={0.01} />
                                    </FormField>
                                    <FormField label={t('imageRotation')} htmlFor="imageRotation" name="imageRotation" noBorder>
                                        <RangeInput id="imageRotation" name="imageRotation" min={0} max={359} step={1} />
                                    </FormField>
                                    <FormField label={t('imageScale')} htmlFor="imageScale" name="imageScale" noBorder>
                                        <RangeInput id="imageScale" name="imageScale" min={0} max={1} step={0.01} />
                                    </FormField>
                                    <FormField label={t('labelColor')} htmlFor="labelColor" name="labelColor">
                                        <TextInput id="labelColor" name="labelColor" type="color" style={{padding: 0}}/>
                                    </FormField>
                                </Box>
                            </Tab>
                        </Tabs>
                        <Button size="medium" icon={<Close/>} onClick={() => onClose()}/>
                    </CardBody>
                    <CardFooter justify="start" pad="medium">
                        <Button label={t('saveButton')} primary type="submit" busy={isCategoryCreating || isCategoryUpdating}/>
                        <Button style={{display: isEditForm ? 'inline-block' : 'none'}} label={t('deleteButton')} onClick={() => handleCategoryDelete(formValues)} />
                    </CardFooter>
                </Card>
            </Form>
        )
    }
}