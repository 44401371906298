import { BoxWindow } from "../components/BoxWindow"
import { NewWheel } from "../components/wheel/NewWheel"
import { RouletteGrid } from "../components/roulette/RouletteGrid"
import { RouletteSettings } from "../components/roulette/RouletteSettings"
import { useEffect, useRef, useState } from "react"
import { RouletteItemsCard } from "../components/roulette/RouletteItemsCard"
import { useGetRouletteQuery } from "../api/rouletteWidgetApi"
import { Box, Card, CardBody, Spinner, Text } from "grommet"
import { useSelector } from "react-redux"
import { authenticationSelector } from "../slices/authSlice"
import { TwitchAuth } from "../components/auth/TwitchAuth"
import { categoryPreviewSelector } from "../slices/rouletteSlice"
import { useTranslation } from "react-i18next"
import useSound from "use-sound"

export const RoulettePage = () => {

    const { t, ready } = useTranslation(['roulette', 'wheel'], { useSuspense: false });

    const [wheelThemes, setWheelThemes] = useState([
        { image: `wheel-theme-1.svg`, radius: 0.99 },
        { image: `wheel-theme-2.svg`, radius: 0.9 },
        { image: `wheel-theme-3.svg`, radius: 0.99 }
    ]);

    const [play, { sound }] = useSound(`${process.env.PUBLIC_URL}/sounds/soundThemes.mp3`, { 
        volume: 0.2,
        sprite: {
            meme: [0, 59000],
            tick: [60900, 1200]
        }
    });

    const [wheelSoundVolume, setWheelSoundVolume] = useState();

    const isAuthenticated = useSelector(authenticationSelector);
    const categoryPreview = useSelector(categoryPreviewSelector);

    const [wheelProps, setWheelProps] = useState({
        name: 'Theme1',
        isInteractive: false,
        debug: false,
        radius: 0.99,
        itemLabelRadius: 0.85,
        itemLabelRadiusMax: 0.2,
        itemLabelRotation: 0,
        itemLabelAlign: 'right',
        itemLabelColors: ['#fff'],
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Roboto Mono',
        itemLabelFontSizeMax: 25,
        lineWidth: 1,
        lineColor: '#ffffff',
        borderColor: '#ffffff',
        borderWidth: 0,
        pointerAngle: 90,
        overlayImage: `${process.env.PUBLIC_URL}/images/wheel-theme-1.svg`
    });
    const [sectors, setSectors] = useState();
    const wheelRef = useRef();
    const [isSpinning, setIsSpinning] = useState();
    const [currentValue, setCurrentValue] = useState();

    const {data: rouletteWidgetData, isError: isRouletteWidgetError, isSuccess: isRouletteLoaded} = useGetRouletteQuery(undefined, { skip: !isAuthenticated });

    const rouletteCategoriesToSectors = (rouletteWidget) => {
        if (rouletteWidget.categoryEntries) {
            return rouletteWidget.categoryEntries.flatMap(category => category.hidden ? [] : categoryToSector(rouletteWidget, category));
        }
        return [];
    }

    const getRouletteSectors = (rouletteWidget, categoryPreview) => {
        const sectors = rouletteCategoriesToSectors(rouletteWidget);
        const keys = Object.keys(categoryPreview)
        if (keys && keys.length > 0) {
            const newSectors = sectors.filter(sector => sector.id !== categoryPreview.id);
            const categoryToSectors = categoryToSector(rouletteWidget, categoryPreview);
            if (Array.isArray(categoryToSectors)) {
                return [...newSectors,  ...categoryToSectors].sort((left, right) => right.id - left.id);
            }
            return [...newSectors, categoryToSectors].sort((left, right) => right.id - left.id);
        }
        return sectors.sort((left, right) => right.id - left.id);
    }

    const categoryRewardsToList = (rewardNames) => {
        return rewardNames.split(",").map(rewardName => rewardName.trim());
    }

    const categoryToSector = (rouletteWidget, category) => {
        const rewardNames = categoryRewardsToList(category.rewardNames)
        return rewardNames.map(rewardName => {
            return {
                id: category.id,
                lotName: rewardName,
                chance: category.rarity / rewardNames.length,
                labelColor: category.labelColor,
                backgroundColor: category.backgroundColor,
                image: category.image ? getCategoryImage(rouletteWidget.ownerId, category.image) : null,
                imageOpacity: category.imageOpacity,
                imageRadius: category.imageRadius,
                imageRotation: category.imageRotation,
                imageScale: category.imageScale,
                
            }
        })
    }

    const getCategoryImage = (userId, image) => {
        return image.startsWith('data') ? image : `${process.env.REACT_APP_API_BASE_URL}/files/${userId}/${image}`;
    }

    const handleWheelDesignValues = (values) => {
        setWheelProps({...wheelProps, ...{
            "overlayImage": `${process.env.PUBLIC_URL}/images/${values["wheelTheme"].image}`,
            "radius": values["wheelTheme"].radius,
            "borderWidth": +values["borderWidth"],
            "borderColor": values["borderColor"],
            "itemLabelFont": values["font"],
            "itemLabelFontSizeMax": +values["fontSize"],
            "itemLabelRadius": +values["labelRadius"],
            "itemLabelRotation": +values["labelRotation"],
            "lineWidth": +values["lineWidth"],
            "lineColor": values["lineColor"],
            "itemLabelStrokeWidth": +values["labelStrokeWidth"],
            "itemLabelStrokeColor": values["labelStrokeColor"]
        }})
    }

    const handleOnSpin = (isSpinning) => {
        setIsSpinning(isSpinning);
    }

    const handleCurrentIndex = (currentSector) => {
        setCurrentValue(currentSector);
    }

    const handleWheelVolume = (value) => {
        setWheelSoundVolume(value);
    }

    useEffect(() => {
        play({ id: 'tick' });
    }, [currentValue])

    useEffect(() => {
        if (isRouletteLoaded) {
            let imageName = rouletteWidgetData["wheelTheme"]
            let theme = wheelThemes.filter(theme => theme.image === imageName)[0]
            setWheelProps({...wheelProps, ...{
                "overlayImage": `${process.env.PUBLIC_URL}/images/${theme.image}`,
                "radius": theme.radius,
                "borderWidth": +rouletteWidgetData["borderWidth"],
                "borderColor": rouletteWidgetData["borderColor"],
                "itemLabelFont": rouletteWidgetData["font"],
                "itemLabelFontSizeMax": +rouletteWidgetData["fontSize"],
                "itemLabelRadius": +rouletteWidgetData["labelRadius"],
                "itemLabelRotation": +rouletteWidgetData["labelRotation"],
                "lineWidth": +rouletteWidgetData["lineWidth"],
                "lineColor": rouletteWidgetData["lineColor"],
                "itemLabelStrokeWidth": +rouletteWidgetData["labelStrokeWidth"],
                "itemLabelStrokeColor": rouletteWidgetData["labelStrokeColor"]
            }})
            setSectors(getRouletteSectors(rouletteWidgetData, categoryPreview))
        }
    }, [isRouletteLoaded])


    useEffect(() => {
        if (isRouletteLoaded) {
            const sectors = getRouletteSectors(rouletteWidgetData, categoryPreview);
            setSectors(sectors)
        }
    }, [categoryPreview])

    useEffect(() => {
        if (rouletteWidgetData) {
            const sectors = getRouletteSectors(rouletteWidgetData, categoryPreview);
            setSectors(sectors)
        }
    }, [rouletteWidgetData])

    useEffect(() => {
        if (sound) {
          sound.volume(wheelSoundVolume)
        }
      }, [wheelSoundVolume])

    if (!ready) {
        return (<Spinner/>)
    }

    if (ready) {
        if (isAuthenticated && !isRouletteWidgetError) {
            return (
                <BoxWindow>
                    <RouletteGrid
                        margin={{top: 'large'}} 
                        leftAlign="center"
                        leftCard={
                            isRouletteLoaded 
                            && sectors
                            && <Box fill>
                                <Box align="center" pad={{bottom: 'small'}}><Text weight={800} size="xlarge">{t('winner')}{currentValue?.lotName}</Text></Box>
                                <NewWheel 
                                    sectors={sectors}
                                    duration={2}
                                    wheelSize={680}
                                    wheelProps={wheelProps}
                                    persistColors={false}
                                    ref={wheelRef}
                                    onSpin={handleOnSpin}
                                    onCurrentIndex={handleCurrentIndex}
                                />
                            </Box>
                        } 
                        rightCard={isRouletteLoaded && <RouletteSettings handleWheelSound={handleWheelVolume} isSpinning={isSpinning} rouletteWidgetSettings={rouletteWidgetData} wheelDesignValuesHandler={handleWheelDesignValues} wheelRef={wheelRef}/>} 
                    />
                    {isRouletteLoaded && (
                        <RouletteItemsCard widget={rouletteWidgetData}/>
                    )}
                </BoxWindow>
            )
        }
        if (!isAuthenticated) {
            return (
                <BoxWindow>
                    <Card background="#222222" fill margin={{top: 'large'}}>
                        <CardBody pad="medium" fill justify="center">
                            <Box fill justify="center" align="center">
                                <TwitchAuth/>
                            </Box>
                        </CardBody>
                    </Card>
                </BoxWindow>
            )
        }
        if (isRouletteWidgetError) {
            return (
                <BoxWindow>
                    <Card background="#222222" fill margin={{top: 'large'}}>
                        <CardBody pad="medium" fill justify="center">
                            <Box fill justify="center" align="center">
                                <Text>{t('rouletteIsNotAvailable')}</Text>
                            </Box>
                        </CardBody>
                    </Card>
                </BoxWindow>
            )
        }
    }
}