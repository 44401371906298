import { Header, Button, Box, Text, Avatar, Nav, Menu } from 'grommet';
import { Logout, SettingsOption, User } from 'grommet-icons';
import { TwitchAuth } from '../auth/TwitchAuth';
import { useGetCurrentUserQuery, useLogoutUserMutation } from '../../api/userApi';
import { WhatYouWishLogo } from '../../icons/WhatYouWishLogo';
import { useDispatch, useSelector } from 'react-redux';
import { authInProgressSelector, authenticationSelector, setAuthenticated, setIsAuthenticating } from '../../slices/authSlice';
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AppHeader = () => {

  const { t } = useTranslation('common')
  const navigate = useNavigate();
  const wss = useSelector(state => state.ws.clients);
  const connected = useSelector(state => state.ws.connected);
  const dispatch = useDispatch();
  const isAuthInProgress = useSelector(authInProgressSelector);
  const isAuthenticated = useSelector(authenticationSelector);
  const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError, isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(undefined, {skip: !isAuthenticated});
  const [logoutUser] = useLogoutUserMutation();

  const myAccountItems = [
    {
      label: <Text weight={'bold'}>{t('settings')}</Text>,
      icon: (<SettingsOption/>),
      onClick: () => navigate('/settings')
    },
    {
      label: <Text weight={'bold'}>{t('signOut')}</Text>,
      icon: (<Logout/>),
      onClick: () => handleSignOut()
    }
  ]

  const handleSignOut = () => {
    dispatch(setAuthenticated(false))
    logoutUser()
    Object.entries(connected).filter((clientName, value) => value)
    .forEach((clientName, value) => wss[clientName].deactivate())
  }

  useEffect(() => {
    if (isCurrentUserError) {
      dispatch(setIsAuthenticating(false))
      dispatch(setAuthenticated(false))
    }
  }, [isCurrentUserError])

  useEffect(() => {
    if (isCurrentUserSuccess) {
      dispatch(setIsAuthenticating(false))
    }
  }, [isCurrentUserSuccess])

  return (
      <Header 
        fill="horizontal"
        pad={{ horizontal: 'medium', vertical: 'small' }}
        background="background-front">
      <Button>
        <Box
          direction="row"
          align="start"
          gap="medium"
          pad={{ vertical: 'none' }}
          responsive={false}
        >
          <Box direction="row" gap="xsmall" wrap>
              <WhatYouWishLogo/>
          </Box>
        </Box>
      </Button>
      <Box direction='row'>
        <Nav direction="row" background="background-front" pad="none" align='center'>
          <Button label={t('auctionHeaderButton')} onClick={() => navigate('/')}/>
          <Button label={t('rouletteHeaderButton')} onClick={() => navigate('/roulette')} />
          {!isAuthenticated && !isAuthInProgress &&  (
            <TwitchAuth/>
          )}
          {isAuthInProgress && (
            <Button busy label="User"/>
          )}
          {isAuthenticated && (
            <Box direction='row' gap='small'>
              <Menu label={<Box gap='small' direction='row' align='center'>
                <User/>
                {isCurrentUserSuccess && (
                  <Text weight={'bold'}>{currentUser.username}</Text>
                )}
              </Box>} items={myAccountItems} size='small'/>
            </Box>
          )}
          <LanguageSelector />
        </Nav>
      </Box>
    </Header>
  );
  };