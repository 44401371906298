import { Box, Button, Card, CardBody, CardFooter, CardHeader, Spinner, Text } from "grommet"
import { Close, Money } from "grommet-icons"
import { Twitch } from "../../icons/Twitch"
import { useDispatch } from "react-redux"
import { removeDonateById } from "../../slices/donatesSlice"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { addLot, updateLotCostByIndex } from "../../slices/auctionDataSlice"
import { forwardRef } from "react"

export const DonateCard = forwardRef(({ item, ...props }, ref) => {

    const { t, ready } = useTranslation('donate-card', { useSuspense: false });
    const dispatch = useDispatch();
    const ws = useSelector(state => state.ws.clients['donates']);
    const connected = useSelector(state => state.ws.connected['donates']);

    const onRemove = () => {
        dispatch(removeDonateById(item.id));
        if (ws !== undefined && connected !== undefined && connected) {
            ws.publish({ destination: '/app/donates/remove', body: JSON.stringify({id: item.id}) });
        }
    }
    const onAddNew = () => {
        dispatch(addLot({lotName: item.message, cost: item.amount}))
        dispatch(removeDonateById(item.id))
        if (ws !== undefined && connected !== undefined && connected) {
            ws.publish({ destination: '/app/donates/remove', body: JSON.stringify(item) });
        }
    }
    const onAddExisting = () => {
        const [lotIndex] = item.suggestedLot;
        dispatch(updateLotCostByIndex({ index: lotIndex, cost: item.amount}))
        dispatch(removeDonateById(item.id))
        if (ws !== undefined && connected !== undefined && connected) {
            ws.publish({ destination: '/app/donates/remove', body: JSON.stringify(item) });
        }
    }

    if (!ready) {
        return (
            <Spinner/>
        )
    }

    if (ready) {
        return (
            <Card key={item.id} ref={ref} {...props} background="dark-1" margin={{bottom: 'medium'}}>
                <CardHeader gap="none" direction="row" align="start" pad={{top: 'medium', left: 'medium', bottom: 'small', right: 'medium'}}>
                    <Box direction="column">
                        <Box direction="row" gap="small" align="center" margin={{bottom: 'small'}}>
                            {item.donate && (<Money/>)}
                            {!item.donate && (<Twitch/>)}
                            <Text size="small" weight={'bold'} truncate="tip">{item.amount} ₴</Text>
                        </Box>
                        <Box>
                            <Text size="small" weight={'bold'} truncate="tip">{item.sender}</Text>
                        </Box>
                    </Box>
                    <Button icon={<Close/>} onClick={onRemove}/>
                </CardHeader>
                <CardBody pad={{top: 'small', left: 'medium', bottom: 'small', right: 'medium'}} height={'xsmall'}>
                    <Text margin={'none'} truncate="tip">{item.message}</Text>
                </CardBody>
                <CardFooter justify="center" pad={{top: 'small', left: 'medium', bottom: 'medium', right: 'medium'}}>
                    {!item.suggestedLot && (
                        <Button onClick={onAddNew} secondary fill label={t('addButton')} size="small"/>
                    )}
                    {item.suggestedLot && (
                        <Box direction="column" fill="horizontal">
                            <Button onClick={onAddNew} secondary label={t('addNewButton')} size="small" margin={{bottom: 'small'}}/>
                            <Button onClick={onAddExisting} secondary label={<Box><Text size="small" truncate="tip">{t('addToButton') + item.suggestedLot[1]}</Text></Box>} size="small"/>
                        </Box>
                    )}
                </CardFooter>
            </Card>
        )
    }
})