import { Box, Card, CardBody, Notification, Spinner, Stack, Tag, Text } from "grommet"
import { TwitchRewards } from "./TwitchRewards";
import { useEffect, useState } from "react";
import { useCreateUserRewardMutation, useDeleteUserRewardMutation, useGetCurrentUserQuery, useGetUserRewardsQuery } from "../../api/userApi";
import { useSelector } from "react-redux";
import { authenticationSelector } from "../../slices/authSlice";
import { TwitchAuth } from "../auth/TwitchAuth";
import { TwitchSettingsLayout } from "./TwitchSettingsLayout";
import { useTranslation } from "react-i18next";

const initState = {rewardName: '', cost: ''}
const toastState = {show: false, message: '', status: ''}

export const TwitchSettings = () => {

    const { t } = useTranslation('twitch-settings');
    const isAuthenticated = useSelector(authenticationSelector);
    const { data: currentUser, isLoading: isCurrentUserLoading, isError: isCurrentUserError, isSuccess: isCurrentUserSuccess } = useGetCurrentUserQuery(undefined, {skip: !isAuthenticated});
    const {data: rewards, isLoading: isRewardsLoading, isError: isRewardsError, isSuccess: isRewardsSuccess} = useGetUserRewardsQuery(undefined, {skip: !isAuthenticated || !currentUser?.partnerOrAffiliate});
    const [createTwitchReward, {isLoading: isRewardCreating}] = useCreateUserRewardMutation();
    const [deleteTwitchReward] = useDeleteUserRewardMutation();
    const [rewardValue, setRewardValue] = useState(initState);
    const [rewardsState, setRewardsState] = useState();
    const [toast, setToast] = useState(toastState);

    const handleRewardFormSubmit = (value) => {
        value.cost = +value.cost;
        value.rewardName = value.rewardName.trim();
        createTwitchReward(value)
            .unwrap()
            .then(() => setRewardValue(initState))
            .catch((error) => setToast({show: true, message: error?.data?.message, status: 'critical'}));
    }

    const handleRewardDelete = (id) => {
        deleteTwitchReward(id);
    }

    useEffect(() => {
        setRewardsState(rewards)
    }, [rewards])

    const handleToastClose = () => {
        setToast(toastState)
    }

    return (
        <Card background="#222222" fill="vertical" margin={{top: 'medium'}}>
            <CardBody pad="medium">
                {isAuthenticated && currentUser?.partnerOrAffiliate && (
                    <TwitchSettingsLayout 
                        rewardValue={rewardValue}
                        onRewardChange={setRewardValue}
                        onRewardSubmit={handleRewardFormSubmit}
                        isRewardCreating={isRewardCreating}
                    />
                )}
                {!isAuthenticated && (
                    <Stack>
                        <TwitchSettingsLayout/>
                        <Box background={{color: '#222222', opacity: 'strong'}} fill justify="center" align="center">
                            <TwitchAuth/>
                        </Box>
                    </Stack>
                )}
                {isAuthenticated && !currentUser?.partnerOrAffiliate && (
                    <Stack>
                        <TwitchSettingsLayout/>
                        <Box background={{color: '#222222', opacity: 'strong'}} fill justify="center" align="center">
                            <Text textAlign="center" weight={'bold'}>{t('rewardsNotAllowedMessage')}</Text>
                        </Box>
                    </Stack>
                )}
                {rewardsState && (
                    <TwitchRewards 
                        data={rewardsState} 
                        onRewardDelete={handleRewardDelete}
                    />
                )}
                {isRewardsLoading && (
                    <Spinner/>
                )}
                {isRewardsError && (
                    <Box>Error</Box>
                )}
                {toast.show && (
                    <Notification
                        toast={{ position: 'top-right' }}
                        status={toast.status}
                        message={toast.message}
                        onClose={handleToastClose}
                    />
                )}
            </CardBody>
        </Card>
    )
}