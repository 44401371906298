import { findBestMatch } from "string-similarity";

const getBestLotName = (lotName, lotsNames) => {
    if (lotsNames.length > 0) {
        const result = findBestMatch(lotName, lotsNames);
        const index = result?.bestMatchIndex;
        const bestMatch = result?.bestMatch;
        return bestMatch?.rating >= 0.5 ? [index, bestMatch?.target] : null;
    }
    return null;
}

export const recalculateSuggestedLots = (items, lotsNames) => {
    return items.map(row => {
        return {...row, ...{suggestedLot: getBestLotName(row.message, lotsNames)}}
    })
}