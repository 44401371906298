import { createSlice } from "@reduxjs/toolkit";

export const rouletteSlice = createSlice({
    name: 'roulette',
    initialState: {
        categoryPreview: {}
    },
    reducers: {
        setCategoryPreview: (state, action) => {
            state.categoryPreview = action.payload;
        },
        resetCategoryPreview: (state) => {
            state.categoryPreview = {};
        }
    }
})

export const { setCategoryPreview, resetCategoryPreview } = rouletteSlice.actions;

export const categoryPreviewSelector = state => state.roulette.categoryPreview

export default rouletteSlice.reducer;