import { Box, FormField, Text, Tip } from "grommet";
import { CircleInformation } from "grommet-icons";

export const FormFieldWithTip = ({children, label, tip, ...props}) => {
    
    return (
        <FormField label={
            <Box direction="row" gap="xsmall" align="center">
                <Text size="xsmall">{label}</Text>
                <Tip dropProps={{align: {left: 'right', top: 'top'}, inline: true}} content={tip} >
                    <CircleInformation size="small"/>
                </Tip>
            </Box>
        } {...props}>
            {children}
        </FormField>
    );
};