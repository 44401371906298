import { Card, CardBody, Heading, Image, Paragraph } from "grommet"
import { useTranslation } from "react-i18next"

export const WheelAbout = () => {

    const { t } = useTranslation(['wheel', 'wheel-about']);
    
    return (
        <Card background="#222222" fill>
            <CardBody pad="medium">
                <Heading level="2" fill margin="none">{t('wheel-about:uiDescTitle')}</Heading>
                <HelpSection text={t('wheel-about:about1')} img={'/images/about1.png'} />
                <HelpSection text={t('wheel-about:about2')} img={'/images/about2.png'} />
                <HelpSection text={t('wheel-about:about3')} img={'/images/about3.png'} />
                <HelpSection text={t('wheel-about:about4')} img={'/images/about4.png'} />
                <HelpSection text={t('wheel-about:about5')} img={'/images/about5.png'} />
                <HelpSection text={t('wheel-about:about6')} img={'/images/about6.png'} />
                <Heading level="2" fill  margin={{top: 'medium', bottom: 'none'}}>{t('wheel-about:wheelDescTitle')}</Heading>
                <HelpSection text={t('wheel-about:about7')} img={'/images/about7.png'} />
                <HelpSection text={t('wheel-about:about8')} img={'/images/about8.png'} />
                <HelpSection text={t('wheel-about:about9')} img={'/images/about9.png'} />
                <HelpSection text={t('wheel-about:about10')} img={'/images/about10.png'} />
                <HelpSection text={t('wheel-about:about11')} img={'/images/about11.png'} />
                <Heading level="2" fill margin={{top: 'medium', bottom: 'none'}}>{t('wheel-about:mathTitle')}</Heading>
                <Paragraph fill>
                    {t('wheel:wheelAboutFirstParagraph')}
                </Paragraph>
                <Image  fit="fit" height={'300px'} width={'300px'} src={`${process.env.PUBLIC_URL}/images/formula1.svg`}  />
                <Paragraph fill>
                    {t('wheel:wheelAboutSecondParagraph')}
                </Paragraph>
                <Image  fit="fit" height={'300px'} width={'300px'} src={`${process.env.PUBLIC_URL}/images/formula2.svg`}  />
            </CardBody>
        </Card>
    )
}

const HelpSection = ({text, img}) => {
    return (
        <>
            <Paragraph fill>{text}</Paragraph>
            <Image fit="fit" height={'1100px'} width={'1100px'} src={`${process.env.PUBLIC_URL}${img}`} />
        </>
    )
}