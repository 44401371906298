import { configureStore } from '@reduxjs/toolkit'

import timerReducer from '../slices/timerSlice'
import donatesReducer from '../slices/donatesSlice'
import socketReducer from '../slices/socketReducer'
import { donatesApi } from '../api/donatesApi'
import { authApi } from '../api/authApi'
import { userApi } from '../api/userApi'
import authReducer from '../slices/authSlice'
import rouletteReducer from '../slices/rouletteSlice'
import { rouletteWidgetApi } from '../api/rouletteWidgetApi'
import { fileApi } from '../api/fileApi'
import { emotesApi } from '../api/emotesApi'
import auctionDataReducer from '../slices/auctionDataSlice'
import wheelSettingsReducer from '../slices/wheelSettingsSlice'
import wheelDataReducer from '../slices/wheelDataSlice'
import { localStoragePersistanceMiddleware } from './localStoragePersistanceMiddleware'
import { calculateWheelDataMiddleware } from './calculateWheelDataMiddleware'
import { setWheelSpinModeMiddleware } from './wheelSpinModeMiddleware'
import { copyWheelDataMiddleware } from './copyWheelDataMiddleware'
import { calculateDonateSuggestionsMiddleware } from './calculateDonateSuggestionsMiddleware'
import { persistanceMiddleware } from './persistanceMiddleware'
import { calculateChancesMiddleware } from './calculateChancesMiddleware'
import { sortItemsMiddleware } from './sortItemsMiddleware'

export default configureStore({
  reducer: {
    auctionData: auctionDataReducer,
    wheelSettings: wheelSettingsReducer,
    wheelData: wheelDataReducer,
    timer: timerReducer,
    donates: donatesReducer,
    ws: socketReducer,
    auth: authReducer,
    roulette: rouletteReducer,
    [donatesApi.reducerPath]: donatesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [rouletteWidgetApi.reducerPath]: rouletteWidgetApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [emotesApi.reducerPath]: emotesApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
    .concat(donatesApi.middleware)
    .concat(authApi.middleware)
    .concat(userApi.middleware)
    .concat(rouletteWidgetApi.middleware)
    .concat(fileApi.middleware)
    .concat(emotesApi.middleware)
    .concat(sortItemsMiddleware)
    .concat(calculateChancesMiddleware)
    .concat(calculateDonateSuggestionsMiddleware)
    .concat(copyWheelDataMiddleware)
    .concat(setWheelSpinModeMiddleware)
    .concat(calculateWheelDataMiddleware)
    .concat(persistanceMiddleware)
    .concat(localStoragePersistanceMiddleware),
  devTools: true
})