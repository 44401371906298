const wheelSettingsActions = [
	"wheelSettings/setSpinStarted",
	"wheelSettings/setWheelDuration",
	"wheelSettings/setWheelSpinMode",
	"wheelSettings/setSupergameEnabled",
	"wheelSettings/setSupergameCostThreshold",
	"wheelSettings/setWheelTheme",
	"wheelSettings/setShowChances",
	"wheelSettings/setWheelSize",
	"wheelSettings/setWheelSound",
	"wheelSettings/setWheelSoundVolume",
	"wheelSettings/setEmote",
	"wheelSettings/setSupergameStarted",
	"wheelSettings/setSupergameFinished",
];

export const localStoragePersistanceMiddleware = ({ getState }) => (next) => async (action) => {
	const result = next(action);
	if (wheelSettingsActions.includes(action.type)) {
		const wheelSettings = getState().wheelSettings;
		localStorage.setItem("wheel-settings-v2", JSON.stringify(wheelSettings));
	}
	if (["wheelData/setWheelMode"].includes(action.type)) {
		const wheelMode = getState().wheelData.wheelMode;
		localStorage.setItem("wheel-mode", wheelMode);
	}
	if (["wheelData/setNotLocked", "wheelData/removeLot"].includes(action.type)) {
		const isNotLocked = getState().wheelData.isNotLocked;
		localStorage.setItem("notLocked", isNotLocked);
	}
	return result;
};
