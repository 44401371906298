import { dbAdapter } from "../db";

export const persistanceMiddleware =
	({ getState }) =>
	(next) =>
	async (action) => {
		const result = next(action);
		const isNotLocked = getState().wheelData.isNotLocked;
		if (
			["auctionData/addLot", "auctionData/updateLot"].includes(
				action.type
			)
		) {
			let lot = action.payload;
			dbAdapter.saveAuctionData(
				{ id: lot.id, lotName: lot.lotName, cost: lot.cost },
				"auctionData"
			);
			if (isNotLocked) {
				dbAdapter.saveAuctionData(
					{ id: lot.id, lotName: lot.lotName, cost: lot.cost },
					"wheelData"
				);
			}
		}
		if (["auctionData/updateLotCostByIndex"].includes(action.type)) {
			let updateData = action.payload;
			let lot = getState().auctionData.items[updateData.index];
			dbAdapter.saveAuctionData(
				{ id: lot.id, lotName: lot.lotName, cost: lot.cost },
				"auctionData"
			);
			if (isNotLocked) {
				dbAdapter.saveAuctionData(
					{ id: lot.id, lotName: lot.lotName, cost: lot.cost },
					"wheelData"
				);
			}
		}
		if (["auctionData/addLots"].includes(action.type)) {
			let lots = action.payload;
			dbAdapter.saveAuctionDataBulk(lots, "auctionData");
			if (isNotLocked) {
				dbAdapter.saveAuctionDataBulk(lots, "wheelData");
			}
		}
		if (["auctionData/deleteLot"].includes(action.type)) {
			let lot = action.payload;
			dbAdapter.removeAuctionData(lot, "auctionData");
			if (isNotLocked) {
				dbAdapter.removeAuctionData(lot, "wheelData");
			}
		}
		if (["auctionData/resetLotCosts"].includes(action.type)) {
			dbAdapter.resetAuctionDataCost("auctionData");
			if (isNotLocked) {
				dbAdapter.resetAuctionDataCost("wheelData");
			}
		}
		if (["auctionData/clearLots"].includes(action.type)) {
			dbAdapter.clearAuctionData("auctionData");
			if (isNotLocked) {
				dbAdapter.clearAuctionData("wheelData");
			}
		}
		if (["wheelData/removeLot"].includes(action.type)) {
			let lot = action.payload;
			dbAdapter.removeAuctionData(lot, "wheelData");
		}
		if (["wheelData/setWheelData"].includes(action.type)) {
			let lots = action.payload;
			if (isNotLocked) {
				dbAdapter.clearAuctionData("wheelData");
				dbAdapter.saveAuctionDataBulk(lots, "wheelData");
			}
		}
		return result;
	};
