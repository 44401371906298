import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import I18NextHttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n.use(I18NextHttpBackend).use(I18nextBrowserLanguageDetector).use(initReactI18next).init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['en', 'uk'],
    preload: ['en', 'uk']
}, () => {})

export default i18n;