import { createSlice } from "@reduxjs/toolkit";

const loadWheelSettings = () => {
	return localStorage.getItem('wheel-settings-v2') ? JSON.parse(localStorage.getItem('wheel-settings-v2')) : {
		spinStarted: false,
		wheelDuration: 10,
		wheelSpinMode: 1,
		supergameEnabled: false,
		supergameCostThreshold: 300,
		supergameCreated: false,
		supergameStarted: false,
		supergameFinished: false,
		wheelTheme: 'classic',
		showChances: false,
		wheelSize: 768,
        wheelSounds: [
            { name: 'Tick', soundId: 'tick', isBackground: false },
            { name: 'Meme', soundId: 'meme', isBackground: true },
            { name: 'KEKW Remix', soundId: 'kekwRemix', isBackground: true }
        ],
		wheelSound: { name: 'Tick', soundId: 'tick', isBackground: false },
		wheelSoundVolume: 0.2,
		emote: undefined,
	}; 
}

export const wheelSettingsSlice = createSlice({
	name: "wheelSettings",
	initialState: loadWheelSettings(),
	reducers: {
		setSpinStarted: (state, action) => {
			const spinStarted = action.payload;
			state.spinStarted = spinStarted;
		},
		setWheelDuration: (state, action) => {
			const wheelDuration = action.payload;
			state.wheelDuration = wheelDuration;
		},
		setWheelSpinMode: (state, action) => {
			const wheelSpinMode = action.payload;
			state.wheelSpinMode = wheelSpinMode;
		},
		setSupergameEnabled: (state, action) => {
			const supergameEnabled = action.payload;
			state.supergameEnabled = supergameEnabled;
		},
		setSupergameCostThreshold: (state, action) => {
			const supergameCostThreshold = action.payload;
			state.supergameCostThreshold = supergameCostThreshold;
		},
		setWheelTheme: (state, action) => {
			const wheelTheme = action.payload;
			state.wheelTheme = wheelTheme;
		},
		setShowChances: (state, action) => {
			const showChances = action.payload;
			state.showChances = showChances;
		},
		setWheelSize: (state, action) => {
			const wheelSize = action.payload;
			state.wheelSize = wheelSize;
		},
		setWheelSound: (state, action) => {
			const wheelSound = action.payload;
			state.wheelSound = wheelSound;
		},
		setWheelSoundVolume: (state, action) => {
			const wheelSoundVolume = action.payload;
			state.wheelSoundVolume = wheelSoundVolume;
		},
		setEmote: (state, action) => {
			const emote = action.payload;
			state.emote = emote;
		},
		setSupergameStarted: (state, action) => {
			const supergameStarted = action.payload;
			state.supergameStarted = supergameStarted;
		},
		setSupergameFinished: (state, action) => {
			const supergameFinished = action.payload;
			state.supergameFinished = supergameFinished;
		}
	},
});

export const {
	setSpinStarted,
	setWheelDuration,
	setWheelSpinMode,
	setSupergameEnabled,
	setSupergameCostThreshold,
	setWheelTheme,
	setShowChances,
	setWheelSize,
	setWheelSound,
	setWheelSoundVolume,
	setEmote,
	setSupergameStarted,
	setSupergameFinished
} = wheelSettingsSlice.actions;

export default wheelSettingsSlice.reducer;
