import { Box, Card, CardBody, List } from "grommet"
import { useSelector } from "react-redux"
import { authenticationSelector } from "../../slices/authSlice"
import { TwitchAuth } from "../auth/TwitchAuth"
import { DraggableDonateCard } from "./DraggableDonateCard"

export const DonatesList = ({ data }) => {

    const isAuthenticated = useSelector(authenticationSelector);

    return (
        <Box fill margin={{top: 'small'}}>
            {isAuthenticated && (
                    <List
                        data={data}
                        primaryKey="id"
                        pad={'none'}
                        margin={{top: 'small'}}
                        children={(item, index) => (
                            <DraggableDonateCard item={item} index={index} />
                        )}
                    />
            )}
            {!isAuthenticated && (
                <Card background="#222222" margin={{ top: 'small' }}>
                    <CardBody pad="medium">
                        <Box justify="center" align="center">
                            <TwitchAuth/>
                        </Box>
                    </CardBody>
                </Card>
            )}
        </Box>
    )
}