import { createApi } from '@reduxjs/toolkit/query/react'
import axios from 'axios';

const axiosBaseQuery = ({ baseUrl, credentials } = { baseUrl: '', credentials: false }) =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers,
                withCredentials: credentials
            })
            return { data: result }
        } catch (axiosError) {
            const err = axiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

export const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: axiosBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/files`, credentials: true }),
    endpoints: (builder) => ({
        getFile: builder.query({
            query: (args) => ({
                url: `/${args.userId}/${args.fileName}`,
                method: 'GET'
            }),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status
        })
    }),
})

export const {
    useLazyGetFileQuery
} = fileApi