import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const rouletteWidgetApi = createApi({
    reducerPath: 'rouletteWidgetApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_BASE_URL}/widgets/roulette`, credentials: 'include' }),
    endpoints: (builder) => ({
        getRoulette: builder.query({
            query: () => ``,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: ['Roulette', 'Categories']
        }),
        getRouletteByToken: builder.query({
            query: (token) => `/${token}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
            providesTags: ['Roulette', 'Categories']
        }),
        updateRouletteWidget: builder.mutation({
            query: (body) => ({url: ``, method: 'PATCH', body: body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Roulette']
        }),
        getRouletteCategory: builder.query({
            query: (id) => `/category/${id}`,
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response.status,
        }),
        createRouletteCategory: builder.mutation({
            query: (body) => ({url: `/category`, method: 'POST', body: body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Categories']
        }),
        updateRouletteCategory: builder.mutation({
            query: (args) => ({url: `/category/${args.id}`, method: 'PUT', body: args.body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Categories']
        }),
        partialUpdateRouletteCategory: builder.mutation({
            query: (args) => ({url: `/category/${args.id}`, method: 'PATCH', body: args.body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Categories']
        }),
        uploadRouletteCategoryImage: builder.mutation({
            query: (args) => {
                let formData = new FormData();
                formData.append('image', args.file);
                return {
                    url: `/category/${args.id}/image`,
                    method: 'PATCH',
                    body: formData,
                    formData: true
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Categories']
        }),
        deleteRouletteCategoryImage: builder.mutation({
            query: (args) => {
                return {
                    url: `/category/${args.id}/image`,
                    method: 'DELETE'
                }
            },
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Categories']
        }),
        deleteRouletteCategory: builder.mutation({
            query: (args) => ({url: `/category/${args.id}`, method: 'DELETE', body: args.body}),
            transformResponse: (response) => response,
            transformErrorResponse: (response) => response,
            invalidatesTags: ['Categories']
        })
    }),
})

export const { 
    useGetRouletteQuery,
    useGetRouletteByTokenQuery,
    useUpdateRouletteWidgetMutation, 
    useGetRouletteCategoryQuery, 
    useCreateRouletteCategoryMutation,
    useUpdateRouletteCategoryMutation,
    usePartialUpdateRouletteCategoryMutation,
    useUploadRouletteCategoryImageMutation,
    useDeleteRouletteCategoryImageMutation,
    useDeleteRouletteCategoryMutation
} = rouletteWidgetApi